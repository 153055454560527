import React from 'react';
import { useEffect, useContext } from 'react';
import { AuthContext } from '../../contexts';

export const withAuth = (WrappedComponent: any, options = {
    adminOnly: false
}) => {
    return (props: any) => {

        const authCtx = useContext(AuthContext);

        useEffect(() => {
            if (!authCtx.isAuthenticated) {
                props.navigate('/bienvenidos');
                return;
            }
            
            if (options.adminOnly) {
                if (!authCtx.isUserAdmin) {
                    props.navigate('/');
                    return;
                }
            }

            return () => {}
        }, [authCtx.isAuthenticated])

        return (
            <WrappedComponent {...props} />
        );
    }
}
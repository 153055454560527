import styled from 'styled-components';
import { classnames, TTextColor } from 'tailwindcss-classnames';

const getTextSize = (size: 'h1' | 'h2' | 'h3' | 'h4' | 'h5') => {
    return classnames(
        size === 'h1' ? 'text-4xl' :
        size === 'h2' ? 'text-3xl' :
        size === 'h3' ? 'text-2xl' :
        size === 'h4' ? 'text-xl' : 'text-lg');
}

export const HeaderElement = (size: 'h1' | 'h2' | 'h3' | 'h4' | 'h5', color: TTextColor, colorDark: TTextColor) => {
    return (
        size === 'h1' ? styled.h1 :
        size === 'h2' ? styled.h2 :
        size === 'h3' ? styled.h3 :
        size === 'h4' ? styled.h4 :
                styled.h5).attrs({
                    className: classnames(getTextSize(size), 'pb-1', 'font-extrabold', color, colorDark)
                })``;
}
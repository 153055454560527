
import React from 'react';
// @ts-ignore
import ReactHoverVideoPlayer from 'react-hover-video-player';
import { HorizontalContainer } from './styles';

interface IProps {
    size: 'sm' | 'md' | 'lg';
    imageURL: string;
    videoURL: string;
    children?: React.ReactNode;
}

export class VideoCardBox extends React.Component<IProps> {
    render() {
        const Container = HorizontalContainer(this.props.size);
        return (<>
            <Container>
                <ReactHoverVideoPlayer
                    videoSrc={this.props.videoURL}
                    // pausedOverlay={
                    //     <img src={this.props.imageURL} alt="" />
                    // }
                    pausedOverlay={this.props.children as any}
                />
            </Container>
        </>)
    }
}
import React from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { getContainer } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
interface IProps {
    type: 'success' | 'info';
    hidden?: boolean;
    text: string;
    icon: IconDefinition;
}

export class MessageLabel extends React.Component<IProps> {
    render() {
        const Container = getContainer(this.props.type);
        return (<div hidden={this.props.hidden}>
            <Container>
                <FontAwesomeIcon icon={this.props.icon} className="mr-2" />
                {this.props.text}
            </Container>
        </div>
        )
    }
}
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { SideSearch, SideSearchItem, TextField } from '../../../molecules';

interface IProps {
    onSearchChanged: (options: any) => void;
}

interface IState {
    isLoading: boolean;
    searchFilter?: {}
}

export class GuidesSideSearch extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            searchFilter: undefined,
            isLoading: true
        };
    }

    componentDidMount() {
        this.initData().then(() => this.setState({isLoading: false}));
    }
    
    private async initData() {}


    render() {
        return <SideSearch title="Filtros de búsqueda">
            <SideSearchItem icon={faSearch} title="Por nombre">
                <TextField errors={[]} placeholder="Digitá el nombre acá..." />
            </SideSearchItem>
        </SideSearch>
    }
}
import Axios, { AxiosResponse } from 'axios';
import { AuthServices } from '../services';
import { getAccessToken } from '../services/auth.service';

const mainAPIInstance = Axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL
});
let jwtTokenInMem: string;

mainAPIInstance.interceptors.request.use(async (config) => {
    config.withCredentials = false;
    const hasAuthorizationLoaded = !!config.headers['authorization'];

    if (hasAuthorizationLoaded) {
        return config;
    }

    if (!jwtTokenInMem) {
       jwtTokenInMem = AuthServices.getAccessToken();

        if (!jwtTokenInMem || !AuthServices.hasAccessToken()) {
            delete config.headers.Authorization;
            return config;
        }
    }
    config.headers.Authorization = `Bearer ${jwtTokenInMem}`;
    return config;
});

mainAPIInstance.interceptors.response.use((value: AxiosResponse) => {
    return value;
}, async (err) => {
    const originalRequest = err.config;
    if (err.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true; 
        // try {
            await AuthServices.refreshToken();
        // } catch (error) {
        //     originalRequest._retry = false;
        //     return Promise.reject();
        // }
        jwtTokenInMem = getAccessToken();
        Axios.defaults.headers.common['Authorization'] = `Bearer ${jwtTokenInMem}`;
        return mainAPIInstance(originalRequest);
    }
    return Promise.reject(err);
})

interface IHeader {
    [key: string]: string;
}

export default abstract class ControllerRequest {
    private jwt: string;
    private params: {[key: string]: string};
    constructor(private resourceUrl: string) {
        this.headers = {};
        this.jwt = '';
        this.params = {};
    }

    private headers: IHeader;

    protected addHeader(key: string, value: string): void {
        this.headers[key] = value;
    }

    protected set jwtToken(value: string) {
        this.jwt = value;
    }

    protected addParams(values?: {[key: string]: string}) {
        if (!values) {
            return;
        }
        this.params = values;
    }

    protected addParam(key: string, value: string) {
        if (!key || !value) {
            return;
        }
        this.params[key] = value;
    }

    private generateUrl(url?: string): string {
        return this.resourceUrl ? `${this.resourceUrl}${url?.startsWith('/') ? url : `/${url}`}` : '';
    }

    private setAuthorizationHeaderToRequest(): void {
        if (!this.jwt) {
            return;
        }
        return this.addHeader('authorization', `Bearer ${this.jwt}`);
    }

    protected async fetch<T>(url?: string): Promise<T> {
        this.beforeExecute();
        const req = mainAPIInstance.get(this.generateUrl(url));
        const res = await req;
        return res.data;
    }
    
    protected async execGET<T>(url?: string): Promise<T> {
        this.beforeExecute();
        const req = mainAPIInstance.get(this.generateUrl(url), {
            headers: this.headers,
            params: this.params
        });

        const res = await req;
        return res.data;
    }

    protected async execPOST<T>(data: any, url?: string): Promise<T> {
        this.beforeExecute();
        const req = mainAPIInstance.post(this.generateUrl(url), data, {
            headers: this.headers
        });
        const res = await req;
        return res.data;
    }

    protected async execPUT<T>(data: any, url?: string): Promise<T> {
        this.beforeExecute();
        const req = mainAPIInstance.put(this.generateUrl(url), data, {
            headers: this.headers
        });
        const res = await req;
        return res.data;
    }

    private beforeExecute() {
        this.setAuthorizationHeaderToRequest();
    }
}

export const removeValue = (key: string) => {
    window.localStorage.removeItem(key);
}

export const setValue = (key: string, value: string) => {
    window.localStorage.setItem(key, value);
}

export const getValue = (key: string) => {
    return window.localStorage.getItem(key);
}
import React, { useEffect } from 'react';
import qs from 'query-string';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../../../contexts';
import { withLayout } from '../../../hocs/withLayout';

const SigninCallback: React.FC<any> = ({ ...props }) => {
    const authContext = React.useContext(AuthContext);
    const location = useLocation();
    const history = useNavigate();
    let isTokenCallback = false;
    let params: any;

    useEffect(() => {
        if (location && location.search) {
            params = qs.parse(location.search);

            if (!params) {
                history('/bienvenidos');
            }

            const { token, state, source, status } = params;
            isTokenCallback = token && state;

            if (!isTokenCallback) {
                history('/bienvenidos');
            }

            if (status === 'user_waiting_for_approval') {
                history('/esperando-aprobacion');
                return;
            }

            const data = { token, source, state, status };
            authContext.signinFromTempToken(data);
            const historyPushUrl = status === 'approved' ? '/' : '/esperando-aprobacion';
            history(historyPushUrl);
        }
        return () => {
            
        }
    }, [location]);


    return (
        <>
            {
                isTokenCallback &&
                <span>Autenticando, por favor espere...</span>
            }
        </>
    )
}

export const SigninCallbackPage = withLayout(SigninCallback);
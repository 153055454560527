import styled from 'styled-components';
import { classnames, TBackgroundColor, TBorderColor, TTextColor } from 'tailwindcss-classnames';

const optionItemBaseClass = classnames(
    'py-1',
    'mr-1',
    'my-0.5',
    'inline-block',
    'px-2',
    'shadow-sm',
    'rounded-sm',
    'cursor-pointer',
    'transition',
    'transition-all',
    'transition-transform',
    'delay-100',
    'duration-300'
);

export const OptionItemAnchor = (id: string, selected = false) => styled.a.attrs({
    className: classnames(optionItemBaseClass,
        !selected ?
            classnames('hover:text-white' as TTextColor,
                'bg-gpas-100' as TBackgroundColor,
                'dark:bg-gpas-200' as TBackgroundColor,
                'shadow-sm',
                'duration-300',
                'transition-colors',
                'hover:border-gpas-100' as TBorderColor,
                'hover:bg-gpas-700' as TBackgroundColor) :
            classnames('bg-gpas-700' as TBackgroundColor,
                'border-gpas-600' as TBorderColor,
                'text-white' as TBackgroundColor)
    )
})``;

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import { TTheme } from '../../../types/common';
import * as S from './styles';

interface IProps {
    children?: React.ReactNode;
    icon: IconDefinition;
    title: string;
    theme?: TTheme;
}

export class ResourceSidebarItem extends React.Component<IProps> {
    render() {
        const theme = this.props.theme || 'light';
        const HeaderContainer = S.ResourceItemHeaderContainer(theme);
        return <S.ResourceItem>
            <HeaderContainer>
                <S.ResourceItemHeader>{this.props.title}</S.ResourceItemHeader>
            </HeaderContainer>
            <S.ResourceContainer>
                {this.props.children}
            </S.ResourceContainer>
        </S.ResourceItem>
    }
}
import styled from 'styled-components';
import { classnames, TTextColor } from 'tailwindcss-classnames'

const classBase = classnames('relative', 'text-sm', 'rounded-sm', 'text-white', 'shadow-md','py-1', 'px-3');

const PrimaryClass = classnames('bg-gpas-700' as TTextColor, 'hover:bg-gpas-900' as TTextColor);
const SecondaryClass = classnames('text-purple-500','hover:text-purple-700','bg-white');
const DangerousClass = classnames('bg-red-500', 'hover:bg-red-700');
const SuccessClass = classnames('bg-green-500', 'hover:bg-green-700');

export const StyledButton = (color: 'primary' | 'secondary' | 'dangerous' |'success', attrs: any) => {
    const ButtonClass = color ==='primary' ? PrimaryClass : color === 'dangerous' ? DangerousClass : color === 'success' ? SuccessClass : SecondaryClass;
    return styled.button.attrs({
        className: classnames(classBase, ButtonClass, attrs.size === 'sm' ? 'text-sm' : 'text-base'),
        ...attrs
    })``;
};

import React from 'react';
import { IProps } from './types'
import * as S from './styles'
import { Link } from 'react-router-dom';

export const NavLink: React.FC<IProps> = ({
    children,
    size,
    color,
    ...props
}: IProps) => {
    const ButtonClass = color === 'primary' ? S.PrimaryClass :
        color === 'secondary' ? S.SecondaryClass : S.WhiteClass;
    return (
        <div className="relative">
            <Link className={ButtonClass} {...props}>
                {children}
            </Link>
        </div>
    )
}

import { useState } from 'react'
import { IUserDetailForAdmin } from '../../dto/user/user-details-for-admin.dto';
import { UserStatusEnum } from '../../enums';
import { UserController } from '../../lib/controllers/user.ctrl';
import { FetchState } from '../../types/common'

const ctrl = new UserController();

export class AdminUsersFilter {
    lastname?: string;
    firstname?: string;
    status?: UserStatusEnum;
}

export const useUserDetailsForAdmin = () => {
    const [status, setStatus] = useState<FetchState>('none');
    const [data, setData] = useState<IUserDetailForAdmin[]>([]);
    const [hasItems, setHasItems] = useState(false);

    const fetchData = async (filters?: AdminUsersFilter) =>  {
        if (status === 'fetching') {
            return;
        }
        setStatus('fetching');
        const result = await ctrl.getUsersDetailsForAdmin(filters);
        setHasItems(Array.isArray(result) && !!result.length);
        setData(result);
        setStatus('fetched');
    }

    const approveUserRequest = async (userId: string): Promise<any> => ctrl.updateUserRequest(userId, 'approved')
    const denyUserRequest = async (userId: string): Promise<any> => ctrl.updateUserRequest(userId, 'denied')

    const cleanData = () => {
        setData([]);
        setStatus('none');
    }
    
    return {
        status,
        data,
        cleanData,
        hasItems,
        fetchData,
        approveUserRequest,
        denyUserRequest
    }
}

import styled from 'styled-components';
import { classnames } from 'tailwindcss-classnames';

export const Container = styled.div.attrs({
    className: classnames(
        'flex-wrap', 
        'relative', 
        'shadow-sm', 
        'rounded-md', 
        'ease-in',
        'duration-300', 
        'w-full',
        'md:w-64',
        'lg:w-64',
        'xl:w-64',
        'bg-gray-50' as any,
        'dark:bg-gray-800' as any,
        'py-4', 
        'px-6',
        'text-gpas-700' as any, 
        'dark:text-white' as any, 
        'text-left')
})``;

export const Header = styled.div.attrs({
    className: classnames('flex-wrap', 'pb-0')
})``;

export const Content = styled.div.attrs({
    className: classnames('flex-wrap', 'pb-2')
})``;

export const Paragraph = styled.p.attrs({
    className: classnames('pb-2', 'text-sm', 'text-gpas-700' as any, 'dark:text-white' as any)
})``;

export const ResourceContainer = styled.div.attrs({
    className: classnames('flex-wrap')
})``;



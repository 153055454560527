import React from 'react'
import { Link } from 'react-router-dom';
import { CardOptionItem } from '..';
import { CardBoxContainer } from '../../containers';
import * as S from './styles';
import { IProps } from './types'

export const ImageCardBox: React.FC<IProps> = ({
    shape,
    title,
    subtitle,
    size,
    cardOptions,
    imageURL,
    children,
    hideSubtitle,
    to,
    ...props
}) => {
    // const Container = shape === 'hori' ? S.HorizontalContainer(size) : S.SquareContainer;
    const Title = S.Title(size);
    const ImageContainer = S.PreviewImage(imageURL, size);
    const hasTo = !!to;
    const TitleElem = () => <Title>{title}</Title>;

    return (
        <CardBoxContainer>
            <ImageContainer>
                <S.HeaderContainer>
                    {
                        subtitle &&
                        <S.Subtitle>{subtitle}</S.Subtitle>
                    }

                    {
                        hasTo ? <Link className="hover:scale-100" to={to}><TitleElem /></Link> : <TitleElem />
                    }
                    {
                        subtitle && !hideSubtitle &&
                        <p className="text-gray-300 text-sm font-semibold">{subtitle}</p>
                    }
                    {
                        Array.isArray(cardOptions) && cardOptions.length > 0 &&
                        <div className="flex space-x-1 pt-2">
                            {
                                Array.isArray(cardOptions) && cardOptions.map((opt, ix) => (
                                    <CardOptionItem key={opt.value} title={opt.title} icon={opt.icon}> <span className="text-xs text-gray-200 font-semibold border-0 p-0">{opt.value}</span></CardOptionItem>
                                ))
                            }
                        </div>
                    }
                </S.HeaderContainer>
            </ImageContainer>
            {/* <S.Content>
                {children}
            </S.Content> */}
        </CardBoxContainer>
    )
}
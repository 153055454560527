import React, { useEffect, useState } from 'react';
import Moment from 'moment';
import { faCalendarWeek, faCircle, faSearch } from '@fortawesome/free-solid-svg-icons'
import { useNavigate, useLocation } from "react-router-dom";
import { IProps, ISearchOptions } from './types';
import { Container, ContentWrapper } from '../../../components/containers';
import { EmptyViewMessage, PageTitle, ResourceCardItem } from '../../../components/molecules';
import { StudiesSideSearch } from '../../../components/organisms';
import { useStudiesHook } from '../../../hooks/Study/StudiesHook';
import { useCategoryGroupHook } from '../../../hooks';
import { withAuth } from '../../../hocs';
import { withLayout } from '../../../hocs/withLayout';

const Studies: React.FC<IProps> = () => {
    const history = useNavigate();
    const {
        studies, 
        fetchStudies, 
        status: fetchStudiesStatus
    } = useStudiesHook();
    const {
        fetchCategoryGroups,
        categoryGroups,
        fetchCategoryGroupsStatus
    } = useCategoryGroupHook();
    const [searchOptions, setSearchOptions] = useState<ISearchOptions>({} as any);
    const { search } = useLocation();

    useEffect(() => {
        fetchStudies(searchOptions);
    }, [searchOptions]);

    useEffect(() => {
        updateOptionsFromSearch(search);
        // fetchCycles();
        fetchCategoryGroups();
        return () => {
            setSearchOptions({
                cycle: '',
                pasGroupCategory: '',
                currentYear: true
            });
        }
    }, [search])

    const updateOptionsFromSearch = (search: string): void => {
        if (!search) {
            return;
        }
        const searchParams = new URLSearchParams(search);
        const pasGroupCategory = searchParams.get('pasGroupCategory') || '';
        const cycle = searchParams.get('cycle') || '';
        setSearchOptions({
            pasGroupCategory,
            cycle,
            currentYear: true
        })
    }

    const updateRouteSearch = (options: ISearchOptions) => {
        let search = `?`;

        if (options.pasGroupCategory) {
            search += `pasGroupCategory=${options.pasGroupCategory}&`;
        }

        if (options.cycle) {
            search += `cycle=${options.cycle}&`;
        }

        history({
            pathname: `/estudios`,
            search: search === '?' ? '' : search
        });
    }

    const onSearchChanged = (options: ISearchOptions) => {
        fetchStudies(options);
        updateRouteSearch(options);
    }

    return (
        <Container>
            <div className="relative top-0 pb-1 w-full shadow-sm h-auto">
                <ContentWrapper>
                    <PageTitle title="Estudios" subtitle="Encontrarás todos los estudios y recursos necesarios." />
                </ContentWrapper>
            </div>

            <ContentWrapper>
                <div className="pb-10 flex-wrap md:flex">
                    <div className="w-full md:w-1/4 md:pr-4 xl:pr-10 top-36">
                        <div className="sticky top-0">
                            {
                                fetchCategoryGroupsStatus === 'fetched' &&
                                <StudiesSideSearch 
                                    categoryGroups={categoryGroups} 
                                    initialGroupCategory={searchOptions.pasGroupCategory}
                                    onSearchChanged={onSearchChanged} />
                            }
                        </div>
                    </div>

                    <div className="w-full md:w-2/4 flex-1 overflow-y-scroll no-scrollbar">
                        <div className="relative">
                            <div className="grid grid-cols-1 md:grid-cols-2">
                                {
                                    fetchStudiesStatus === 'fetched' && studies.length > 0 ? studies.map((study, ix) => {
                                        const attrs = [{ 
                                            value: study.cycle?.subtitle, icon: faCircle 
                                        }];

                                        if (study.startAt && study.endAt) {
                                            attrs.push({ value: `${Moment(study.startAt).format('DD/MMM')} - ${Moment(study.endAt).format('DD/MMM')}`, icon: faCalendarWeek });
                                        }

                                        return <div key={study.id} className="mr-2 mb-3">
                                         <ResourceCardItem
                                            attributes={attrs}
                                            size="md"
                                            hideSubtitle={false}
                                            to={`/estudios/${study.slug}`}
                                            imageURL={study.imageURL}
                                            subtitle={study.subtitle}
                                            title={study.title}
                                        />
                                        </div>
                                    }) : fetchStudiesStatus === 'fetching' ? <></> :
                                        <div className="xl:px-auto items-center w-full">
                                            <EmptyViewMessage icon={faSearch} title="Sin resultados" paragraph="Parece que no hay resultados para esta búsqueda" />
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

            </ContentWrapper>
        </Container>
    )
}

export const StudiesPage = withLayout(withAuth(Studies))
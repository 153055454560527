import { useState } from 'react';
import { IFetchStudiesFilter } from '.';
import { CycleController, StudyController } from '../../../lib/controllers';
import { ICycle, IStudy } from '../../../types';
import { FetchState } from '../../../types/common';

let CYCLES_CACHE: ICycle[] = [];

export const useStudiesHook = () => {
    const [studies, setStudies] = useState<IStudy[]>([]);
    const [currentStudy, setCurrentStudy] = useState<IStudy>();
    const [status, setStatus] = useState<FetchState>('none');
    const [cycles, setCycles] = useState<ICycle[]>([]);
    const studyCtrl = new StudyController();
    const cycleCtrl = new CycleController();

    const clearSingleStudySearch = () => {
        setStatus('none');
        setCurrentStudy(undefined);
    }

    const fetchCycles = async (): Promise<void> => {
        if (Array.isArray(CYCLES_CACHE) && !!CYCLES_CACHE.length) {
            setCycles(CYCLES_CACHE);
            return;
        }
        const cycles = await cycleCtrl.getAll();
        CYCLES_CACHE = cycles;
        setCycles(cycles);
    }

    const fetchStudies = async (options: IFetchStudiesFilter): Promise<void> => {
        if (status === 'fetching') {
            return;
        }

        setStatus('fetching');
        const data = await studyCtrl.getList(options);
        setStudies(data);
        setStatus('fetched');
    }

    const getSingleStudy = async (slug: string): Promise<IStudy> => {
        setStatus('fetching');
        const study = await studyCtrl.getSingleStudy(slug);
        setCurrentStudy(study);
        setStatus('fetched');
        return study;
    }

    return {
        fetchStudies,
        getSingleStudy,
        clearSingleStudySearch,
        fetchCycles,
        currentStudy,
        studies,
        cycles,
        status,
    };
}
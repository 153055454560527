import styled from 'styled-components';
import { classnames, TBackgroundColor } from 'tailwindcss-classnames';

export const HeaderContainer = styled.div.attrs({
    className: classnames('w-full', 'bg-gpas-700' as TBackgroundColor, 'dark:bg-pas-500' as TBackgroundColor, 'top-0', 'z-10', 'md:h-12', 'xl:h-14')
})``;

export const Content = styled.div.attrs({
    className: classnames('relative', 'max-w-screen-lg', 'xl:max-w-screen-lg', 'mx-auto', 'md:py-1', 'xl:py-1')
})``;

export const ItemsContainer = styled.div.attrs({
    className: classnames('flex','items-center','justify-between')
})`flex-grow: 1;`;
import { useEffect, useState } from 'react';
import { IPasGroupCategory } from '../types';
import { PasGroupCategoryController } from '../lib/controllers';

const pasGroupCategoryApiService = new PasGroupCategoryController();
let cache: IPasGroupCategory[]|null = null;

export const useCategoriesFetch = () => {
    const [status, setStatus] = useState('none');
    const [data, setData] = useState<IPasGroupCategory[]>();

    const fetchData = async () => {
        if (cache) {
            setData(cache);
            setStatus('fetched');
            return;
        }

        const data = await pasGroupCategoryApiService.getAll();
        cache = data;
        setData(data);
        setStatus('fetched');
    }

    useEffect(() => {
        setStatus('fetching');
        fetchData();
        return () => {
            setStatus('none');
            setData([]);
        }
    }, [status])
    return {
        status,
        data
    };
}
import styled from 'styled-components';
import { classnames } from 'tailwindcss-classnames';

export const AnswersContainer = styled.div.attrs({
    className: classnames('relative', 'px-0', 'pb-8', 'pt-12', 'min-h-full')
})``;

export const AnswersHeader = styled.div.attrs({
    className: classnames('relative', 'flex', 'flex-col', 'px-0', 'py-2', 'px-6', 'bg-gray-50', 'border-gray-100', 'rounded-md')
})`
border-bottom: 1px solid;
`;
import React from 'react';
import Moment from 'moment';
import { faBook, faCircleNotch, faDownload, faFilePdf, faFlagCheckered } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ICycle } from '../../../types';
import { IFile } from '../../../types/common';
import { IPasGroupCategory } from '../../../types/pas-group-category.type';
import { ResourceSidebar, ResourceSidebarItem } from '../../molecules';

interface IProps {
    title: string;
    subtitle: string;
    pasGroupCategory: IPasGroupCategory;
    cycle: ICycle;
    startAt: Date;
    endAt: Date;
    files?: IFile[];
    goBackAction: () => void;
    onHideAction?: (hidde: boolean) => void;
}

export class SingleStudySidebar extends React.Component<IProps> {
    private generateDateText(date: Date): string {
        if (!date) {
            return '';
        }
        return `${Moment(date).format('dddd')} ${Moment(date).format('DD')} de ${Moment(date).format('MMMM')}`;
    }
    render() {
        return <ResourceSidebar
            goBackAction={this.props.goBackAction}
            title={this.props.title}
            subtitle={this.props.subtitle}>
            <>
                {
                    this.props.pasGroupCategory &&
                    <ResourceSidebarItem theme="light" title="Categoría" icon={faBook}>
                        <span> {this.props.pasGroupCategory?.name} </span>
                    </ResourceSidebarItem>
                }
                {
                    this.props.cycle &&
                    <ResourceSidebarItem theme="light" title="Ciclo" icon={faCircleNotch}>
                        <span className="text-sm"> {this.props.cycle.title} </span>
                    </ResourceSidebarItem>
                }
                {
                    this.props.startAt && this.props.endAt &&
                    <ResourceSidebarItem theme="light" title="Semana" icon={faFlagCheckered}>
                        <span>Del <strong> {this.generateDateText(this.props.startAt)}</strong> al <strong>{this.generateDateText(this.props.endAt)}</strong> del {Moment(this.props.endAt).year()}</span>
                    </ResourceSidebarItem>
                }

                {
                    Array.isArray(this.props.files) && !!this.props.files.length &&
                    <ResourceSidebarItem theme="light" title="Descargas" icon={faDownload}>
                        <ul>
                            {
                                this.props.files.map(file => {
                                    return <li key={file.id}>
                                        <a className="flex space-x-2 items-center dark:text-gpas-200 underline" rel="noreferrer" target="_blank" href={file.url}>
                                            <FontAwesomeIcon icon={faFilePdf} />
                                            <span>
                                                {file.name}
                                            </span>
                                        </a>
                                    </li>
                                })
                            }
                        </ul>
                    </ResourceSidebarItem>
                }
            </>
        </ResourceSidebar>
    }
}
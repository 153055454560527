import { IRefreshTokenResponse, ISigninResponse } from '../interfaces';
import ControllerRequest from './_controller.base';

export class AuthController extends ControllerRequest {
    constructor() {
        super('auth')
    }

    refreshToken(data: {refreshToken: string }): Promise<IRefreshTokenResponse> {
        this.jwtToken = data.refreshToken;
        return this.execPOST<IRefreshTokenResponse>({}, '/refresh-token')
    }

    signOut(data: { jwt: string, refreshToken: string }): Promise<any> {
        this.jwtToken = data.jwt;
        return this.execPOST({
            refreshToken: data.refreshToken
        }, '/sign-out');
    }

    token(data: { token: string, state: string}): Promise<ISigninResponse> {
        this.jwtToken = data.token;
        return this.execPOST<ISigninResponse>(data, '/token');
    }
}
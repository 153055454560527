import { faBook, faNewspaper, faQuestion } from '@fortawesome/free-solid-svg-icons';
import React, { useContext } from 'react';
import { AuthContext } from '../../../contexts';
import { NavbarActions } from '../../molecules/NavbarActions/NavbarActions';
import { NavbarBrand } from '../../molecules/NavbarBrand/NavbarBrand';
import { HeaderOptionLink } from './HeaderOptionLink';
import { HeaderContainer, Content, ItemsContainer } from './styles';
import { IProps } from './types';

export const Header: React.FC<IProps> = ({ ...props }) => {
    const authCtx = useContext(AuthContext);
    return (
        <HeaderContainer>
            <Content>
                <ItemsContainer>
                    <NavbarBrand />
                    {
                        authCtx.isAuthenticated &&
                        <div className="flex space-x-2 md:space-x-5 uppercase" >
                            <HeaderOptionLink icon={faNewspaper} title="Inicio" url="/" />
                            <HeaderOptionLink icon={faBook} title="Estudios" url="/estudios" />
                            <HeaderOptionLink icon={faBook} title="Recursos" url="/recursos" />
                            {
                                process.env.SHOW_QUESTIONS === '1' &&
                                <HeaderOptionLink icon={faQuestion} title="Consultas" url="/preguntas" />
                            }
                        </div>
                    }
                    <NavbarActions />
                </ItemsContainer>
            </Content>
        </HeaderContainer>
    )
}
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { ContentWrapper, Markdown } from '../../components/containers';
import { BackgroundPageTitle, MarkdownContent, VideoPlayer } from '../../components/molecules';
import { NewsSidebar } from '../../components/organisms';
import { NewsController } from '../../lib/controllers';
import { INews } from '../../types';
import { IProps } from './types';
import { withLayout } from '../../hocs/withLayout';
import { withAdmin, withAuth } from '../../hocs';

export const NewsSingle: React.FC<IProps> = ({ ...props }) => {
    const params = useParams();
    const navigate = useNavigate();
    const [news, setNews] = useState<INews>({} as any);
    const newsCtrl = new NewsController();

    const getNews = async (slug: string): Promise<any> => {
        const data = await newsCtrl.getSingleNews(slug);
        setNews(data);
    }

    useEffect(() => {
        getNews(params.slug as string).then();
    }, [params])

    const goBackAction = () => {
        navigate(-1);
    }

    return (
        <>
            {
                !!news && !!news.imageURL &&
                <BackgroundPageTitle imageURL={news.imageURL} title={news.title} subtitle={news.subtitle} heigthSize="sm" />
            }

            <ContentWrapper>
                <div className="flex flex-col lg:flex-row my-0 md:my-2">
                    <div className="w-full lg:w-3/4 flex bg-white dark:bg-gray-800 px-0 md:px-10 py-4 shadow-sm rounded-md">
                        <div className="flew-wrap md:px-4 md:py-6">
                            {
                                news.externalVideoURL &&
                                <div className="pb-6">
                                    <VideoPlayer videoURL={news.externalVideoURL} />
                                </div>
                            }
                            {
                                news.showImageInContent &&
                                <div className="w-full pb-4">
                                    <img src={news.imageURL} className="bg-cover" alt="" />
                                </div>
                            }
                            <MarkdownContent>
                                <Markdown content={news.content} />
                            </MarkdownContent>

                        </div>
                    </div>

                    <div className="w-full lg:w-1/4 relative px-6">
                        <NewsSidebar goBackAction={goBackAction} {...news} />
                    </div>
                </div>
            </ContentWrapper>
        </>
    )
}

export const NewsSinglePage = withLayout(withAuth(NewsSingle))
import styled from 'styled-components';

export const IntroBox = styled.div.attrs({
    className: 'flex-1 px-6 text-left max-w-2xl md:max-w-3xl mx-6'
})``;

export const IntroLeftBox = styled.div.attrs({
    className: 'flex-1 relative mt-12 xl:mt-0 px-6'
})``;

export const IntroImage = styled.img`
    max-width: 500px
`;
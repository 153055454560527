import styled from 'styled-components';
import { classnames } from 'tailwindcss-classnames';

const commonClasses = classnames('h-auto', 'relative', 'rounded-b-2xl', 'shadow-xl', 'px-0', 'pb-4', 'bg-gray-100', 'flex-wrap', 'relative', 'hover:shadow-2xl', 'hover:scale-100');

export const HorizontalContainer = (size: 'sm'|'md'|'lg') => {
    const height = size === 'md' ? '60px' : 
        size === 'lg' ? '100px' : 
        size === 'sm' ? '80px' : '40px';
    return styled.div.attrs({
    className: classnames(commonClasses)
})`
    min-height: ${height}; 
`};
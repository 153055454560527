import React from 'react';
import { ISupportTicket, ISupportTicketComment } from '../../../types';
import ReactMoment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faClock, faComments, faHandsHelping, faReply, faSign, faTimesCircle, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { SupportController } from '../../../lib/controllers';
import { QuestionComment } from '..';
import { SupportTicketStateEnum } from '../../../enums';

interface IProps {
    supportTicket: ISupportTicket;
    sendNewComment: (comment: string) => Promise<void>;
    fetchComments: () => Promise<ISupportTicketComment[]>;
}

interface IState {
    state: 'none' | 'adding-comment' | 'comment-sent',
    newComment: string;
    showComments: boolean;
    comments: ISupportTicketComment[];
}

const supportCtrl = new SupportController();

export class Question extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.prepareNewComment = this.prepareNewComment.bind(this);
        this.cancelComment = this.cancelComment.bind(this);
        this.sendNewComment = this.sendNewComment.bind(this);
        this.onNewCommentChanged = this.onNewCommentChanged.bind(this);
        this.fetchComments = this.fetchComments.bind(this);

        this.state = {
            state: 'none',
            newComment: '',
            showComments: false,
            comments: []
        };
    }

    prepareNewComment(e: any) {
        e.preventDefault();
        this.setState({
            state: 'adding-comment'
        });
    }

    async fetchComments() {
        const { showComments } = this.state;

        if (showComments) {
            this.setState({ showComments: false });
            return;
        }

        this.setState({
            showComments: true
        });

        const comments = await supportCtrl.getTicketComments(this.props.supportTicket.id);
        this.setState({
            comments
        });
    }

    cancelComment(e: any) {
        e.preventDefault();
        this.setState({
            state: 'none',
            newComment: ''
        })
    }

    async sendNewComment(e: any) {
        e.preventDefault();
        const errors = this.beforeSendNewComment();
        if (!!errors) {
            return;
        }
        const { newComment } = this.state;
        await this.props.sendNewComment(newComment);
        this.setState({
            state: 'comment-sent',
            newComment: ''
        });
    }

    onNewCommentChanged(e: any) {
        e.preventDefault();
        const { value } = e.target;
        this.setState({
            newComment: value
        });
    }

    private beforeSendNewComment() {
        let errors = {};
        if (!this.state.newComment) {
            errors = {
                message: 'El comentario es necesario, debe completarlo para enviar el mensaje'
            };
            return errors;
        }
        return null;
    }

    render() {
        const Option = (data: { text: string, icon: IconDefinition, action: any }) => <button className="bg-gpas-700 text-white font-semibold hover:text-gpas-700 ring-1 ring-gpas-400  hover:bg-gpas-100 delay-75 transform px-2 py-1 shadow-md rounded-sm text-sm"
            onClick={data.action}>
            <FontAwesomeIcon icon={data.icon} className="text-gpas-400" />
            <span> {data.text}</span>
        </button>;

        return (
            <div className="relative w-full border-b-2 border-gray-100 shadow-sm" style={{ borderBottom: '1px solid' }}>
                <div className="flex px-6 py-5">
                    <img className="rounded-full w-8 h-8 ring-2 xs:hidden md:visible ring-gpas-500 my-2" src={this.props.supportTicket.byUser.avatarURL} alt={this.props.supportTicket.byUser.username} />
                    <div className="relative w-full px-3 pt-1 flex-wrap">
                        <div className="pb-2 flex-wrap md:flex space-x-1 justify-between">
                            <div className="flex flex-col">
                                <span className="text-sm mr-2 text-gpas-700 font-semibold">{this.props.supportTicket.byUser.firstname} {this.props.supportTicket.byUser.lastname}</span>
                                <span className="text-xs mr-2 text-gpas-600 font-semibold">
                                    <ReactMoment date={this.props.supportTicket.createdAt} format="DD/MM/YYYY hh:mm" />
                                </span>
                            </div>
                            <div className="flex space-x-1">
                                <div className="flex space-x-1 items-center bg-gpas-200 text-gpas-100 rounded-sm px-2 py-0">
                                    <FontAwesomeIcon icon={faClock} className="text-gpas-400 text-xs hidden md:block" />
                                    <span className=" text-gpas-700 text-xs font-semibold">
                                        <ReactMoment fromNow={true} date={this.props.supportTicket.createdAt}/>
                                    </span>
                                </div>
                                {
                                    this.props.supportTicket.category &&
                                    <div className="flex space-x-1 items-center bg-gpas-200 text-gpas-700 rounded-sm px-2 py-0">
                                        <FontAwesomeIcon icon={faHandsHelping} className="text-gpas-400 hidden md:block" />
                                        <span className="text-gpas-700 text-xs font-semibold">
                                            {this.props.supportTicket.category.name}
                                        </span>
                                    </div>
                                }
                                <div className={`flex space-x-1 items-center bg-${this.props.supportTicket.status === SupportTicketStateEnum.CREATED ? 'yellow-100' :
                                    this.props.supportTicket.status === SupportTicketStateEnum.RESOLVED ? 'success-600' : 'green-200'} font-semibold text-white rounded-sm px-2 py-0`}>
                                    <FontAwesomeIcon icon={faSign} className="text-gray-700 text-xs hidden md:block" />
                                    <span className="text-xs text-gpas-700">
                                        {this.props.supportTicket.statusName}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <p className="text-gray-800 text-sm py-1 pb-3">{this.props.supportTicket.message}</p>
                        {
                            this.state.state === 'none' &&
                            <div className="w-full items-center flex content-between space-x-2 pt-1">
                                <Option text="Responder" icon={faReply} action={this.prepareNewComment} />
                                <Option text="Ver respuestas" icon={faComments} action={this.fetchComments} />
                            </div>
                        }
                        {
                            this.state.showComments && !!this.state.comments.length &&
                            <div className="relative pt-1">
                                {
                                    this.state.comments.map(comment => <QuestionComment key={comment.id} comment={comment} />)
                                }
                            </div>
                        }
                        {
                            this.state.state !== 'none' &&
                            <div className="relative flex-wrap border-gray-100 border-t">
                                <div className="w-full" hidden={this.state.state === 'comment-sent'}>
                                    <textarea value={this.state.newComment} placeholder="Escribí tu respuesta" onChange={this.onNewCommentChanged} className="w-full px-3 py-2 inline-block resize border rounded-md" name="newComment" rows={3}></textarea>
                                </div>
                                <div className="flex space-content space-x-2">
                                    {
                                        this.state.state === 'adding-comment' &&
                                        <>
                                            <Option text="Responder" icon={faReply} action={this.sendNewComment} />
                                            <Option text="Cancelar" icon={faTimesCircle} action={this.cancelComment} />
                                        </>
                                    }
                                    {
                                        this.state.state === 'comment-sent' &&
                                        <Option text="Mensaje recibido, gracias." icon={faCheckCircle} action={this.cancelComment} />
                                    }

                                </div>
                            </div>
                        }

                    </div>
                </div>
            </div>
        )
    }
}
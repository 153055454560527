import { faBook, faClock, faDownload, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import Moment from 'moment';
import { NavLink } from 'react-router-dom';
import { INews, IStudy } from '../../../types';
import { ResourceSidebar, ResourceSidebarItem } from '../../molecules';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IProps extends INews {
    goBackAction: () => void;
}

export class NewsSidebar extends React.Component<IProps> {
    render() {
        const hasStudy = !!this.props.study;
        const study: IStudy = this.props.study as IStudy;
        return (
            <ResourceSidebar goBackAction={this.props.goBackAction} title={this.props.title} subtitle={this.props.subtitle}>
                <ResourceSidebarItem icon={faClock} title="Publicado">
                    <span>
                        <FontAwesomeIcon icon={faClock} className="mr-1" />
                        {Moment(this.props.startsAt || new Date()).locale('es').fromNow()}</span>
                </ResourceSidebarItem>
                {
                    hasStudy &&
                    <ResourceSidebarItem icon={faBook} title="Estudio">
                        <NavLink to={`/estudios/${study?.slug}`}>
                            <span>{study.title}</span>
                        </NavLink>
                    </ResourceSidebarItem>
                }
                {
                    Array.isArray(this.props.files) && !!this.props.files.length &&
                    <ResourceSidebarItem theme="light" title="Descargas" icon={faDownload}>
                        <ul>
                            {
                                this.props.files.map(file => {
                                    return <li key={file.id}>
                                        <a className="hover:underline flex space-x-2 items-center" rel="noreferrer" target="_blank" href={file.url}>
                                            <FontAwesomeIcon icon={faFileDownload} />
                                            <span>
                                                {file.name}
                                            </span>
                                        </a>
                                    </li>
                                })
                            }
                        </ul>
                    </ResourceSidebarItem>
                }
            </ResourceSidebar>
        )
    }
}
import { IUserDetailForAdmin } from '../../dto/user/user-details-for-admin.dto';
import { AdminUsersFilter } from '../../hooks/Users';
import { IUser } from '../../types/user.type';
import ControllerRequest from './_controller.base';

export class UserController extends ControllerRequest {
    constructor() {
        super('user');
    }

    getMe(): Promise<IUser> {
        return this.execGET('/me');
    }
    
    getUsersDetailsForAdmin(filter?: AdminUsersFilter): Promise<IUserDetailForAdmin[]> {
        if (filter) {
            if (filter.status) {
                this.addParam('status', filter.status);
            }
            if (filter.firstname) {
                this.addParam('firstname', filter.firstname);
            }
            if (filter.lastname) {
                this.addParam('lastname', filter.lastname);
            }
        }
        return this.execGET('/for-admin');
    }

    updateUserRequest(userId: string, action: 'approved'|'denied') {
        const url = `/${userId}/request/${action}`;
        const data = {
            userId
        };
        return this.execPUT(data, url);
    }
}
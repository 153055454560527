import styled from 'styled-components';
import { classnames, TBackgroundColor, TTextColor } from 'tailwindcss-classnames';

export const OptionsItem = styled.div.attrs({
    className: classnames('flex', 'items-center', 'space-x-1', 'rounded-sm', 'py-1', 'px-2',
    'bg-gpas-700' as TBackgroundColor,
    'transition-transform')
})``;

export const OptionTitleSpan = styled.span.attrs({
    className: classnames('block', 'text-sm','text-gpas-700' as TTextColor)
})`
    line-height: 0.5rem;
    font-weight: 200;
`;
import React from 'react';
import { IProps } from './types'
import * as S from './styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Button: React.FC<IProps> = ({
    children, 
    size,
    type,
    icon,
    color,
    btnRef,
    ...props
}: IProps) => {
    
    const Button = S.StyledButton(color, props);
    return (
        <Button ref={btnRef}>
            {
                !!icon && <FontAwesomeIcon icon={icon} className="pr-1"/>
            }
            {children}
        </Button>
    )
}

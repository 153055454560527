import { useEffect, useState } from 'react';
import { IResourceGroup } from '../../types';
import { ResourceController } from '../../lib/controllers';
import { FetchState, ResourceTypeType } from '../../types/common';

const ctrl = new ResourceController();
let cache: IResourceGroup[];

export const useResourceGroup = (type: ResourceTypeType) => {
    const [state, setState] = useState<FetchState>('none');
    const [data, setData] = useState<IResourceGroup[]>();
    const [hasResults, setHasResults] = useState(false);
    const [groupedBy, setGroupedBy] = useState('');

    const fetchData = async () => {
        // if (cache) {
        //     setData(cache);
        //     setState('fetched');
        //     return;
        // }

        const data = await ctrl.getResourceGroupByType(type, groupedBy);
        cache = data;
        setData(data);
        setHasResults(data && data.length > 0);
        setState('fetched');
    }

    useEffect(() => {
        if (!groupedBy) {
            return;
        }

        if (state === 'fetching') {
            return;
        }
        setState('fetching');
        fetchData();

        return () => {
            setState('fetched');
            setData([]);
        }
    }, [groupedBy]);

    return {
        state,
        data,
        setGroupedBy,
        hasResults
    };
}
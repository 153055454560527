import styled from 'styled-components'
import { classnames, TBackgroundColor } from 'tailwindcss-classnames';

export const Container = styled.footer.attrs({
    className: classnames(
        'bottom-0', 
        'relative', 
        'bg-gray-300', 
        'dark:bg-gray-800' as TBackgroundColor, 
        'h-10'
    )
})`width: 100%;`;

export const GPLogoImage = styled.img.attrs({
    className: classnames('relative')
})`
    height: 12px;
`;
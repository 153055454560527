import styled from 'styled-components';
import { classnames, TBackgroundColor } from 'tailwindcss-classnames';

export const Container = styled.div.attrs({
    className: classnames('pb-0')
})``;

export const HeaderContainer = styled.div.attrs({
    className: classnames('py-3', 'px-6', 'bg-gpas-100' as any,  'dark:bg-gpas-700' as any, 'rounded-t-md')
})`border: inherit;`

export const MenuItemContainer = styled.div.attrs({
    className: classnames('border-b-2')
})``;

export const ItemsContent = styled.div.attrs({
    className: classnames('py-0', 'w-full', 'flex', 'flex-col', 'divide-y', 'divide-gray-600', 'bg-gray-900' as TBackgroundColor)
})``;

export const ItemAnchor = styled.a.attrs({
    className: classnames('py-3')
})`
    border-color: #f7f7f7;
    font-weigth: 400;
`;
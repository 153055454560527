import styled from 'styled-components';
import { classnames } from 'tailwindcss-classnames';

export const Container = styled.div.attrs({
    className: classnames('relative', 'flex-wrap', 'pb-10', 'mb-8', 'border-gray-700', 'delay-500', 'transition-transform', 'transition-all')
})`border-bottom: 1px solid;`;

export const GroupSection = styled.div.attrs({
    className: classnames('relative', 'flex', 'space-x-2', 'text-left', 'pl-0', 'pr-4', 'pb-3', 'pt-0', 'items-center')
})``;

export const GroupTitle = styled.span.attrs({
    className: classnames('text-2xl', 'text-white' as any,  'font-bold', 'pb-2')
})``;
export const GroupSubtitle = styled.span.attrs({
    className: classnames('text-gpas-200' as any)
})``;

export const ResourcesSection = styled.div.attrs({
    className: classnames('relative', 'py-0', 'w-full')
})``;

export const ResourceItemsSection = styled.div.attrs({
    className: classnames('relative')
})``;

export const ResourceItem = styled.div.attrs({
    className: classnames('relative')
})``;

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CardBoxContainer, Container, ContentWrapper } from '../../components/containers';
import { PageTitle } from '../../components/molecules';
import { ResourceSlider } from '../../components/molecules/ResourceSlider';
import { useResourceGroup, useResourceGroupResources } from '../../hooks';
import { ResourceController } from '../../lib/controllers';
import { withLayout } from '../../hocs/withLayout';
import { withAuth } from '../../hocs';

const ctrl = new ResourceController();
const getAvailableYears = (firstYear: number): number[] => {
    const nowYear = (new Date()).getFullYear();
    const totalYearsToAdd = nowYear - firstYear;
    const years = [nowYear];
    for (let ix = 1; ix <= totalYearsToAdd; ix++) {
        years.push(nowYear - ix);
    }
    return years;
};
const AVAILABLE_YEARS = getAvailableYears(2018);
const DEFAULT_YEAR = AVAILABLE_YEARS[0];
const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

export const Resources: React.FC = () => {

    const query = useQuery();
    const yearStr = query.get('year');
    let yearNum = 0;
    try {
        yearNum = parseInt(typeof yearStr === 'string' ? yearStr : DEFAULT_YEAR.toString());

    } catch (error) {
        yearNum = DEFAULT_YEAR;
    }

    const [currentYear, setCurrentYear] = useState(yearNum);
    const { data: studiesGroypData, hasResults: studiesHasResults, state: studiesGroupState, setGroupedBy } = useResourceGroup('study');
    const { state: studiesResourceStatus } = useResourceGroupResources('study');

    const fetchStudiesResources = async (groupId: string) => {
        return ctrl.getResourceGroupResources('study', groupId);
    }

    useEffect(() => {
        setGroupedBy(currentYear.toString());
        return () => {
            setGroupedBy('');
        }
    }, [currentYear, setGroupedBy])

    return (
        <Container>
            <ContentWrapper>
                <div className="pb-6">
                    <PageTitle title="Recursos" subtitle="Encontrarás todos los recursos en Grupos PAS" />
                </div>
                <div className="flex-wrap md:flex w-full">
                    <div className="w-full md:w-1/3 xl:w-1/4 pr-4 md:pr-8 mt-2 md:mt-6">
                        <CardBoxContainer>
                            <div className="relative flex-wrap items-center px-4 pt-2 pb-6">
                                <h2 className="text-gpas-700 dark:text-white">Búsqueda</h2>
                                <div className="pt-0 pb-1">
                                    <span className="text-gray-500 dark:text-gray-200 text-sm">Por año</span>
                                </div>
                                <div className="relative flex-wrap space-y-2">
                                    {
                                        AVAILABLE_YEARS.map(year => (
                                            <button onClick={(e) => {
                                                e.preventDefault();
                                                setCurrentYear(year);
                                            }} className={`relative px-2 mr-2 ring-gpas-200 ring-2 rounded-md shadow-sm 
                                                    ${year !== currentYear ? 'text-gpas-700 bg-gpas-100' : 'text-gpas-100 bg-gpas-700'}`} key={year}>{year}</button>
                                        ))
                                    }
                                </div>
                                <p className="pt-6">Mostrando el <span className="font-bold text-gpas-700"> {currentYear} </span> </p>
                            </div>
                        </CardBoxContainer>
                    </div>
                    <div className="w-full md:w-2/3 xl:w-3/4">
                        {
                            studiesGroupState === 'fetched' && Array.isArray(studiesGroypData) && studiesHasResults ? studiesGroypData.map((group, ix) =>
                                <ResourceSlider key={ix} group={group} state={studiesResourceStatus} fetchResources={fetchStudiesResources} />) :
                                <p>No hay resultados para este año</p>
                        }
                    </div>
                </div>
            </ContentWrapper>
        </Container>
    )
}

export const ResourcesPage = withLayout(withAuth(Resources))

import { FieldProps  } from 'formik';
import React from 'react';
import { Input, SpanError } from './styles';

interface IProps extends FieldProps {
    type: 'text'|'password'|'number',
    placeholder?: string
}

export class FormInputField extends React.Component<IProps> {
    render() {
        const { field } = this.props;
        const { touched, errors } = this.props.form;
        // const InputEl = errors[field.name] ? InputError : Input;
        return <div>
            <Input placeholder={this.props.placeholder} autoComplete="off" type={this.props.type} {...field} />
            {
                touched[field.name] &&
                errors[field.name] &&  <SpanError>{
                    // errors[field.name]
                }</SpanError>            
            }
        </div>
    }
}
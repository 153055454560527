import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Title } from '../../atoms';

interface IProps {
    title: string;
    paragraph: string;
    icon: IconDefinition;
}

export class EmptyViewMessage extends React.Component<IProps> {
    render() {
        return (
            <div className="h-screen w-full relative">
                <div className="relative flex items-center w-96 space-x-8 px-8 bg-yellow-50 dark:bg-gpas-700 border-gpas-700 py-8 border-4 rounded-md shadow-sm">
                    <div className="flex pb-2">
                        <FontAwesomeIcon icon={this.props.icon} size="3x" className="text-gpas-100" />
                    </div>
                    <div className="flex flex-col relative">
                        <Title color="text-gpas-700 dark:text-white" heading="h2">{this.props.title}</Title>
                        <p className="text-gpas-700 dark:text-gray-200 pb-2">{this.props.paragraph}</p>
                    </div>
                </div>
            </div>
        )
    }
}
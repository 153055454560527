import styled from 'styled-components';
import { classnames, TBackgroundColor, TTextColor } from 'tailwindcss-classnames';

export const Container = styled.div.attrs({
    className: classnames('flex-wrap', 'px-4', 'pt-3', 'pb-4', 'bg-gray-900' as TBackgroundColor, 'rounded-b-md')
})``;

export const Header = styled.div.attrs({
    className: classnames('flex-wrap', 
    'content-center', 
    'px-4', 
    'pt-1', 
    'bg-gpas-100' as any, 
    'dark:bg-gpas-700' as any, 
    'rounded-t-md')
})``;

export const Title = styled.h3.attrs({
    className: classnames('text-gray-800', 'font-bold', 'text-2xl', 'pb-0', 'text-gpas-700' as TTextColor, 'dark:text-white' as TTextColor)
})``;

export const Content = styled.div.attrs({
    className: classnames('flex-wrap', 'space-y-6', 'divide-y', 'divide-gray-200', 'divide-y-4', 'bg-gray-900')
})``;
import React, { useContext, useEffect, useState } from 'react'
import { faBook, faExternalLinkAlt, faFile, faQuestion, faSearch, faUsers } from '@fortawesome/free-solid-svg-icons';
import { Container, ContentWrapper } from '../../components/containers'
import { SidebarMenu } from '../../components/molecules';
import { Layout, NewsCardBox, UserWelcome } from '../../components/organisms';
import { INews } from '../../types/news.type';
import { NewsController } from '../../lib/controllers';
import { Subtitle, Title } from '../../components/atoms';
import { AuthContext } from '../../contexts';
import { withAuth } from '../../hocs';
import { withLayout } from '../../hocs/withLayout';

const newsApiService = new NewsController();

export const HomePageTx: React.FC<any> = ({ ...props }) => {
    const [loading, setLoading] = useState(true);
    const authCtx = useContext(AuthContext);
    const [featuredNews, setFeaturedNews] = useState<INews[]>([]);
    const [mainNews, setMainNews] = useState<INews>();

    useEffect(() => {
        if (!loading) {
            return;
        }
        loadNewsInfo();
        return () => {
            setLoading(false);
        }
    }, [loading]);

    const loadNewsInfo = async () => {
        const featured = await newsApiService.getFeaturedNews();
        const main = featured.find(news => news.featured === true);
        setMainNews(main);
        setFeaturedNews(featured.filter(news => !news.featured));
        setLoading(false);
    }

    if (loading) {
        return <span className="h-screen text-center place-content-center">Cargando noticias...</span>
    }

    const quickMenuOptions = [
        { title: 'Estudios', url: '/estudios', icon: faBook },
        { title: 'Recursos', url: '/recursos', icon: faFile },
        { title: 'Ir a Planning Center', isExternal: true, url: 'https://groups.planningcenteronline.com/my_groups', icon: faExternalLinkAlt }
    ];

    if (process.env.SHOW_QUESTIONS === '1') {
        quickMenuOptions.push({ title: 'Consultas', url: '/preguntas', icon: faQuestion });
    }

    if (authCtx.isUserAdmin) {
        quickMenuOptions.push({ title: 'Gestión usuarios', url: '/gestion-usuarios', icon: faUsers });
    }

    return (
        <>
            <div className="w-full shadow-sm">
                <ContentWrapper>
                    <Container>
                        <div className="text-left pb-6 mt-2 flex-wrap">
                            <Title color='text-gpas-700' heading="h1">Para esta semana</Title>
                            <p className="text-gray-700 dark:text-gray-400 text-sm font-semibold">¿Qué tenés que saber para esta semana para tu Grupo Pas?</p>
                        </div>
                        <div className="flex-wrap md:flex space-y-4 md:space-y-0">
                            <div className="sm:w-full md:w-1/4 xl:w-1/4 md:pr-4 ">
                                <SidebarMenu subtitle="Opciones rápidas" title={`¡Bienvenido! 👋`} items={quickMenuOptions} />
                            </div>
                            <div className="relative w-full md:w-3/4">
                                {
                                    mainNews && <NewsCardBox to={`/noticias/${mainNews.slug}`} size="lg" news={mainNews} />
                                }
                            </div>

                        </div>
                    </Container>
                </ContentWrapper>
            </div>
            <ContentWrapper>
                <Container>
                    <div className="xl:w-full xl:pb-4 relative">
                        <div className="text-left pb-6">
                            <Title heading="h2">Más noticias en Grupos PAS</Title>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-2 gap-x-4">
                            {
                                Array.isArray(featuredNews) &&
                                featuredNews.map((news, ix) => (
                                    <NewsCardBox key={ix} to={`/noticias/${news.slug}`} size="md" news={news} />
                                ))
                            }
                        </div>
                    </div>
                </Container>
            </ContentWrapper>

            <ContentWrapper>
                <Container>
                    <div className="w-full xl:mt-2 xl:mb-8">
                        <UserWelcome navItems={[
                            { title: 'Estudios', subtitle: 'Ingresá para obtener el material del ciclo actual', to: '/estudios', icon: faBook },
                            { title: 'Recursos', subtitle: 'Revisá estudios anteriores y material adicional para tu crecimiento', to: '/recursos', icon: faSearch },
                            { title: 'Asistencia', subtitle: 'Tomá la asistencia de tu Grupo PAS en Planning Center', to: 'https://groups.planningcenteronline.com/my_groups', icon: faQuestion, isExternal: true },
                        ]} />
                    </div>
                </Container>

                <div className="bg-gray-50" hidden>
                    <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
                        <h2 className="text-3xl font-bold tracking-tight text-dark sm:text-4xl">
                            <span className="block">¿Cómo podemos ayudarte?</span>
                            <span className="block text-gpas-700">Si necesitás ayuda, ingresá acá.</span>
                        </h2>
                        <div className="mt-8 lex lg:mt-0 lg:flex-shrink-0">
                            <div className="inline-flex rounded-md shadow">
                                <a href="/preguntas" className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gpas-700 hover:bg-gpas-800">
                                    Ingresá tu consulta
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </>
    )
}
export const HomePage = withLayout(
    withAuth(HomePageTx)
);
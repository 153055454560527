import styled from 'styled-components';
import { classnames, TTextColor } from 'tailwindcss-classnames';

export const Container = styled.div.attrs({
    className: classnames('mb-8', 'py-1')
})``;

export const Title = styled.h2.attrs({
    className: classnames('text-3xl', 'text-gpas-700' as TTextColor, 'dark:text-gray-300' as TTextColor, 'pb-1', 'font-bold')
})``;

export const Subtitle = styled.h4.attrs({
    className: classnames('text-gray-600')
})``

export const NavContainer = styled.div.attrs({
    className: classnames('relative', 'pt-6', 'flex', 'grid', 'grid-cols-1', 'md:grid-cols-3', 'md:space-x-4')
})``;

export const NavCardContainer = styled.div.attrs({
    className: classnames('relative', 'bg-gray-900' as any, 'pb-2', 'md:pb-0')
})`
`;

export const NavItem = styled.div.attrs({
    className: classnames('flex-col', 'text-center', 'py-8','mx-8')
})`
    min-height: 140px;
`;

export const ItemTitle = styled.h3.attrs({
    className: classnames('border-purple-800', 'py-4', 'dark:text-gray-400' as TTextColor)
})`
    font-weight: 600;
    font-size: 1.4rem;
`;

export const ItemSubtitle = styled.p.attrs({
    className: classnames('border-gray-300', 'dark:text-gray-400' as TTextColor)
})``;
import { useState } from 'react';
import { PasGroupCategoryController } from '../../../lib/controllers';
import { IPasGroupCategory } from '../../../types';
import { FetchState } from '../../../types/common';

let CACHE_DATA: IPasGroupCategory[] = [];

export const useCategoryGroupHook = () => {
    const [data, setData] = useState<IPasGroupCategory[]>([]);
    const [status, setStatus] = useState<FetchState>('none');
    const controller = new PasGroupCategoryController();

    const cleanData = () => {
        setStatus('none');
        setData([]);
    }

    const fetchData = async (): Promise<void> => {
        if (status === 'fetching') {
            return;
        }

        setStatus('fetching');
        const data = await controller.getAll();
        setData(data);
        CACHE_DATA = data;
        setStatus('fetched');
    }

    return {
        fetchCategoryGroups: fetchData,
        fetchCategoryGroupsStatus: status,
        cleanData,
        categoryGroups: data,
        status
    };
}
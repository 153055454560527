import React from 'react';
import ReactPlayer from 'react-player';
import styles from './VideoPlayer.module.css';

interface IProps {
    videoURL: string;
}

export class VideoPlayer extends React.Component<IProps> {
    render() {
        return (
            <div className={styles.playerWrapper} style={{minHeight: '300px'}}>
                <ReactPlayer width="100%" height="100%" className={styles.reactPlayer} url={this.props.videoURL} />
            </div>
        )
    }
}
import React from 'react';
import { IProps } from './types';
import { Header } from '../Header';
import { Footer } from '../Footer';

export const Layout: React.FC<IProps> = ({ ...props }) => {
    return (
        <div className="flex min-h-screen flex-col dark:bg-gray-900 text-white dark:text-gray-100">
            <div className="sticky z-10 top-0">
                <Header />
            </div>
            <main className="relative min-h-full flex-1 overflow-y-auto pb-10">
                {props.children}
            </main>
            <Footer />
        </div>
    )
}
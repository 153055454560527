import React, { createContext, useState } from 'react';
import { AddSupportTicketDto } from '../../dto';
import { SupportController } from '../../lib/controllers';
import { ISupportTicket } from '../../types';
import { SupportCurrentState } from './types';

interface ISupportContext {
    currentState: SupportCurrentState,
    changeCurrentState: (newState: SupportCurrentState) => void,
    sendNewComment: (supportTicket: ISupportTicket, comment: string) => Promise<any>
}

const supportCtrl = new SupportController();

export const SupportContext = createContext<ISupportContext>({
    currentState: 'none',
    changeCurrentState: () => {},
    sendNewComment: async (st: ISupportTicket, c: string) => {}
});

export const SupportProvider = ({ children }: any) => {
    const [currentState, setCurrentState] = useState<SupportCurrentState>('none');
    
    const changeCurrentState = (newState: SupportCurrentState) => {
        setCurrentState(newState);
    }

    const sendNewComment = async (supportTicket: ISupportTicket, newComment: string): Promise<any> => {
        const data = new AddSupportTicketDto(newComment);
        const res = await supportCtrl.addTicketComment(supportTicket, data);
        return res;
    }

    return <SupportContext.Provider value={{
        currentState,
        changeCurrentState,
        sendNewComment
    }}>
        {children}
    </SupportContext.Provider>
}
import React from 'react';
import { Button, LinkButton } from '../../components/atoms';
import { TextField } from '../../components/molecules';
import { FormContainer } from '../../components/containers';
import { Formik } from 'formik';
import { IProps, IState, IFormValue } from './types';

export class SignInForm extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            pcSent: false
        }
    }
    render() {
        return (<Formik
            initialValues={{
                username: this.props.username, password: ''
            }}
            onSubmit={(values: IFormValue, { setSubmitting }: any) => {
                setTimeout(() => {
                    setSubmitting(false);
                    this.props.onSubmit(values);
                }, 2000);
            }}>
            {({
                handleChange,
                handleSubmit,
                values,
                isSubmitting,
            }) => (
                    <FormContainer>
                        <form onSubmit={handleSubmit}>
                            <TextField value={values.username} type="email" onChange={handleChange} title="Correo electrónico" errors={[]} />
                            <TextField value={values.password} type="password" onChange={handleChange} title="Contraseña" errors={[]} />
                            <Button color="primary" disabled={!isSubmitting} type="submit">Iniciar sesión</Button>
                            {/* <Button color="secondary" disabled={!isSubmitting} type="button">Olvidé mi contraseña</Button> */}
                        </form>
                        <div className="border-b-2 border-gray-100 my-8"></div>
                        <div className="items-center align-middle w-100 text-center">
                            <LinkButton url={this.props.planningCenterAuthorizeUrl} color="primary" onClick={() => this.setState({pcSent: true})}>
                                { !this.state.pcSent ?
                                    <span>Inicie con Planning Center </span> : 
                                    <span>Espere... </span>
                                }
                            </LinkButton>
                        </div>
                    </FormContainer>
                )}
        </Formik>
        );
    }
}

import styled from 'styled-components';
import { classnames } from 'tailwindcss-classnames';

const classnameBase = classnames('relative', 'bg-cover', 'my-0',
    'pt-10', 'pb-6', 'flex-wrap', 'shadow-sm');

const getHeightSize = (size: 'sm' | 'lg' = 'lg') => {
    if (size === 'sm') {
        return classnames('h-36', 'md:h-36', 'lg:h-36');
    }
    return classnames('h-48', 'sm:h-48', 'md:h-20', 'lg:h-64');
}

export const ImageContainer = (imageUrl: string, heightSize?: 'sm' | 'lg') => styled.div.attrs({
    className: classnames(getHeightSize(heightSize), 'bg-title' as any, 'bg-gpas-700' as any),
})``;

export const ImageChild = (imageUrl: string) => styled.div.attrs({
    className: classnames(classnameBase, 'bg-cover', 'bg-center', 'child' as any),
})`
    background-image: linear-gradient(rgba(0,0,0,0.1), rgba(0, 0, 10, 0.9) ), url('${imageUrl}');
    width: 100%;
    height: 100%;
`;

export const MarkdonwClass = styled.div.attrs({
    className: classnames('relative', 'text-justify', 'text-lg')
})``;

export const OptionsContainer = styled.div.attrs({
    className: classnames('w-full', 'flex', 'content-between', 'py-4')
})``;
import styled from 'styled-components';
import { classnames } from 'tailwindcss-classnames';

const classnameBase = classnames('px-4', 'py-4', 'rounded-md', 'flex', 'space-x-4', 'items-center');

export const getContainer = (type: 'success'|'info') => type === 'success' ? SuccessContainer : InfoContainer

export const SuccessContainer = styled.div.attrs({
    className: classnames(classnameBase, 'bg-green-100', 'text-green-800')
})``;

const InfoContainer = styled.div.attrs({
    className: classnames(classnameBase, 'bg-blue-200', 'text-blue-700')
})``;
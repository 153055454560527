import styled from 'styled-components';
import { classnames } from 'tailwindcss-classnames';

export const MainContent = styled.div.attrs({
    className: classnames('relative', 'flex-wrap', 'md:flex', 'min-h-screen', 'my-6')
})``;

export const SingleAnswerContainer = styled.div.attrs({
    className: classnames('relative', 'border-b-2', 'xl:w-10/12', 'border-gray-100')
})`
border-bottom: 1px solid;
`;
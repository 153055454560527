import React from "react";
import { Layout } from "../components";
import { useNavigate } from "react-router-dom";

export const withLayout = (WrappedComponent: any) => {
    return (props: any) => {
        const navigate = useNavigate();
        return <Layout>
            <WrappedComponent navigate={navigate} {...props} />
        </Layout>
    }
}

import { IFetchStudiesFilter } from '../../hooks/Study/StudiesHook/types';
import { IStudy } from '../../types/study.type';
import ControllerRequest from './_controller.base';

export class StudyController extends ControllerRequest {
    constructor() {
        super('study');
    }

    getList(options?: IFetchStudiesFilter): Promise<IStudy[]> {
        if (options) {
            if (!!options.pasGroupCategory) {
                this.addParam('pasGroupCategory', options.pasGroupCategory);
            }

            if (!!options.cycle) {
                this.addParam('cycle', options.cycle);
            }

        }
        return this.execGET('/');
    }

    getSingleStudy(slug: string): Promise<IStudy> {
        return this.execGET(`/${slug}`);
    }
}
import { ErrorMessage } from '../../atoms/ErrorMessage';
import React from 'react';
import { IProps } from './types';
import { Input } from './styles';

export const TextField: React.FC<IProps> = ({
    errors,
    ...props}: IProps) => {
    return (
        <div className="lg:mb-6">
            <label className="block text-md font-medium lg:pb-2 leading-5 text-gray-700">{props.title}</label>
            <Input {...props} className="mt-1 form-input py-3 px-4 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"/>
            <ErrorMessage errors={errors} />
        </div>
    )
}

import React from 'react';
import {AuthProvider, SupportProvider} from '../contexts';
import AppRoutes from '../Routes';
import { BrowserRouter } from 'react-router-dom';

function App() {
  return (
    <AuthProvider>
      <SupportProvider>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </SupportProvider>
    </AuthProvider>
  );
}

export default App;

import React from 'react';
import { faBook, faCalendarAlt, faSearch } from '@fortawesome/free-solid-svg-icons';
import { IPasGroupCategory } from '../../../types/pas-group-category.type';
import { SideSearch, SideSearchItem } from '../../molecules';
import * as S from './styles';
import { ICycle } from '../../../types';
import { LinkButton } from '../../atoms';

interface IProps {
    onSearchChanged: (options: any) => void;
    categoryGroups: IPasGroupCategory[];
    initialGroupCategory?: string;
}

interface IState {
    isLoading: boolean;
    searchFilter: {
        cycle?: string;
        pasGroupCategory?: string;
    }
}
export class StudiesSideSearch extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            searchFilter: {
                pasGroupCategory: props.initialGroupCategory || ''
            },
            isLoading: true
        };
        this.onCategoryClick = this.onCategoryClick.bind(this);
        this.onCycleClick = this.onCycleClick.bind(this);
    }

    get selectedPasCategoryGroup(): string | undefined {
        return this.state.searchFilter?.pasGroupCategory;
    }

    get selectedCycle(): string | undefined {
        return this.state.searchFilter?.cycle;
    }

    onCategoryClick(pasGroupCategory: IPasGroupCategory) {
        let { 
            searchFilter 
        } = this.state;

        if (searchFilter.pasGroupCategory === pasGroupCategory.id) {
            searchFilter.pasGroupCategory = ''
        } else {
            searchFilter.pasGroupCategory = pasGroupCategory.id;
        }

        this.setState({ 
            searchFilter 
        });
        this.props.onSearchChanged(searchFilter);
    }

    onCycleClick(cycle: ICycle) {
        let { searchFilter } = this.state;
        searchFilter.cycle = cycle.id;
        this.props.onSearchChanged(searchFilter);
        this.setState({ searchFilter });
    }

    render() {
        return <SideSearch title="Búsqueda" subtitle="Estudios actuales">
            <SideSearchItem icon={faBook} title="Categorías">
                {
                    this.props.categoryGroups.map(cat => {
                        const Option = S.OptionItemAnchor(cat.id, this.selectedPasCategoryGroup === cat.id);
                        return <Option key={cat.id} onClick={() => this.onCategoryClick(cat)}>{cat.name}</Option>
                    }
                    )
                }
            </SideSearchItem>

            <SideSearchItem icon={faSearch} title="Estudios pasados">
                <div className="relative">
                    <div className="w-full">
                        <div className="relative flex">
                            <div className="w-full">
                                <LinkButton buttonStyle="link" url="/recursos" icon={faCalendarAlt} >
                                    Recursos anteriores
                                </LinkButton>
                            </div>
                        </div>
                    </div>
                </div>
            </SideSearchItem>
        </SideSearch>
    }
}
import React from 'react';
import { Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

interface IProps {
    index: number;
    children?: React.ReactNode;
    onClicked: () => void;
}

export class CarouselSlider extends React.Component<IProps> {
    constructor(props: IProps) {
        super(props);
        this.onSliderClicked = this.onSliderClicked.bind(this);
    }

    onSliderClicked(e: any) {
        e.preventDefault();
        this.props.onClicked();
    }

    render() {
        return (<Slide index={this.props.index} onClick={this.onSliderClicked}>
            {this.props.children}
        </Slide>)
    }
}
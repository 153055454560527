import { INews } from '../../types/news.type';
import ControllerRequest from './_controller.base';

export class NewsController extends ControllerRequest {
    constructor() {
        super('news');
    }

    getFeaturedNews(): Promise<INews[]> {
        return this.execGET('/featured');
    }

    getSingleNews(slug: string): Promise<INews> {
        return this.execGET(`/${slug}`);
    }
}
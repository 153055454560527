import React from 'react';
import { TTextColor } from 'tailwindcss-classnames';
import { HeaderElement } from './style';
import { IProps } from './types'

export const Title: React.FC<IProps> = ({
    children,
    heading,
    color,
    colorDark,
}: IProps) => {
    const colorClass: any = color || 'text-gpas-700' as TTextColor;
    const colorDarkClass: any = colorDark || 'dark:text-white' as TTextColor;
    const Element = HeaderElement(heading, colorClass, colorDarkClass);
    return (
        <Element>{children}</Element>
    )
}

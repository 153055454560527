import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CardBoxContainer } from '../../containers';
import { Title, Container, NavContainer, NavItem, NavCardContainer, ItemTitle, ItemSubtitle } from './styles';
import { IProps } from './types';
import { Link } from 'react-router-dom';

export const UserWelcome: React.FC<IProps> = ({
    navItems,
    ...props }) => {
    navItems = navItems || [];

    return (
        <Container>
            <div className="text-left">
                <Title>Base de Conocimiento</Title>
            </div>
            <NavContainer>
                {
                    navItems.map((item, ix) => {
                        const ActionLink = item.isExternal ? 
                            ({children}: any) => (<a href={item.to}>
                                {children}
                            </a>) :
                            ({children}: any) => (<Link to={item.to}>
                                {children}
                            </Link>)
                        return (
                            <NavCardContainer key={ix}>
                                <CardBoxContainer roundedSize='md'>
                                    <ActionLink>
                                        <NavItem>
                                            <FontAwesomeIcon icon={item.icon} size="3x" className="text-gpas-600" />
                                            <ItemTitle>
                                                {item.title}
                                            </ItemTitle>
                                            <ItemSubtitle>
                                                {item.subtitle}
                                            </ItemSubtitle>
                                        </NavItem>
                                    </ActionLink>
                                </CardBoxContainer>
                            </NavCardContainer>
                        )
                    })
                }
            </NavContainer>
        </Container>
    )
}
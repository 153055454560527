import styled from 'styled-components';
import { classnames } from 'tailwindcss-classnames';

const commonClasses = classnames('rounded-b-2xl', 'shadow-xl', 'px-0', 'pb-4', 'bg-gray-100', 'flex-wrap', 'relative', 'hover:shadow-2xl', 'hover:scale-100');

// className: commonClasses + classnames('shadow-lg')
export const HorizontalContainer = (size: 'sm'|'md'|'lg') => {
    const height = size === 'md' ? '60px' : 
        size === 'lg' ? '100px' : 
        size === 'sm' ? '80px' : '40px';
    return styled.div.attrs({
    className: classnames(commonClasses)
})`
    min-height: ${height}; 
`};
export const SquareContainer = styled.div.attrs({})``;

export const Title = (size: 'sm'|'md'|'lg') => styled.h3.attrs({
    className: classnames('pt-2', 'pb-2', 'font-bold', 'text-white', 'hover:scale-105', 
        'hover:text-gpas-100' as any, 'transition-all', 'transition-transform', 'delay-75')
})`
    font-size: ${size === 'lg' ? '2rem' : size === 'md' ? '1.6rem' : '1.2rem'};
    font-weight: 400;
`;

export const Subtitle = styled.span.attrs({
    className: classnames('pt-1', 'text-xs', 'text-white', 'from-gray-900' )
})`
`;
// font-weight: 400;

export const PreviewImage = (imageURL: string, size: 'sm'|'md'|'lg') => {
    const height = size === 'md' ? '230px' : 
        size === 'lg' ? '330px' : 
        size === 'sm' ? '130px' : '100px';

    return styled.div.attrs({
        className: classnames('flex', 'rounded-sm', 'relative', 'hover:scale-105', 'bg-cover', 'overflow-hidden')
    })`
        background: linear-gradient(rgba(0,0,0,0.2), rgba(0, 0, 0, 0.9) ), url('${imageURL}') no-repeat ;
        background-size:     cover;      
        background-repeat:   no-repeat;
        min-height: ${height};
        background-position: center center; 
`;
};

export const HeaderContainer = styled.div.attrs({
    className: classnames('py-4', 'px-6', 'bg-transparent', 'rounded-tr-sm', 'absolute')
})`
    bottom: 0;
`;

export const Content = styled.div.attrs({
    className: classnames('px-4', 'py-2')
})``
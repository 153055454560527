import { faAngleRight, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { ResourceCardItem } from '..';
import { ResourceContainer } from '../../containers';
import * as Styles from './styles';
import * as Types from './types';

export class ResourceSlider extends React.Component<Types.IProps, Types.IState> {
    constructor(props: Types.IProps) {
        super(props);
        this.fetchData = this.fetchData.bind(this);
        this.state = {
            state: 'fetching',
            resources: [],
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    async fetchData() {
        this.setState({
            state: 'fetching'
        });
        const data = await this.props.fetchResources(this.props.group.groupId);
        this.setState({
            resources: data,
            state: 'fetched'
        });
    }

    render() {
        const LoadingDataSection = () => <span><FontAwesomeIcon icon={faSpinner} spin={true} /> Cargando</span>;
        return (
            <Styles.Container>
                <Styles.GroupSection>
                    <Styles.GroupTitle>{this.props.group.subtitle}</Styles.GroupTitle>
                    <FontAwesomeIcon icon={faAngleRight} className="text-gpas-200" />
                    <Styles.GroupSubtitle>{this.props.group.name}</Styles.GroupSubtitle>
                </Styles.GroupSection>

                {
                    this.state.state === 'fetching' ?
                        <LoadingDataSection /> :
                        <Styles.ResourcesSection>
                            <ResourceContainer type="grid">
                                {
                                    this.state.resources.map(resource => (
                                        <ResourceCardItem
                                            key={resource.title}
                                            attributes={[]}
                                            hideSubtitle={false}
                                            size="md"
                                            to={`/estudios/${resource.slug}`}
                                            imageURL={resource.imageURL}
                                            subtitle={resource.subtitle}
                                            title={resource.title}
                                        />
                                    ))
                                }
                            </ResourceContainer>
                        </Styles.ResourcesSection>
                }

            </Styles.Container>
        )
    }
}
import React from 'react';
import { PageTitle } from '../PageTitle';
import { ContentWrapper } from '../../containers';
import * as S from './styles';

interface IProps {
    imageURL: string;
    title: string;
    subtitle?: string;
    heigthSize?: 'sm' | 'lg';
}

export class BackgroundPageTitle extends React.Component<IProps> {
    render() {
        let BackgroundContainer = S.ImageContainer(this.props.imageURL, this.props.heigthSize);
        let BackgroundChild = S.ImageChild(this.props.imageURL);
        return (

            <BackgroundContainer>
                <BackgroundChild>
                    <ContentWrapper>
                        <div className="bottom-0 absolute items-end pb-2 md:pb-4">
                            <PageTitle title={this.props.title} color="text-white" subtitle={this.props.subtitle} />
                        </div>
                    </ContentWrapper>
                </BackgroundChild>
            </BackgroundContainer>)
    }
}
import { FieldProps } from 'formik';
import React from 'react';
import { Input, SpanError } from './styles';

interface IProps extends FieldProps {
    type: 'text'|'password'|'number',
    placeholder?: string
    rows?: number;
}

export class FormTextareaField extends React.Component<IProps> {
    render() {
        const { field } = this.props;
        const { touched, errors } = this.props.form;
        const rows = this.props.rows || 3;
        return <div>
            <Input placeholder={this.props.placeholder} rows={rows} autoComplete="off" {...field} />
            {
                touched[field.name] &&
                errors[field.name] &&  <SpanError>{errors[field.name] as string}</SpanError>            
            }
        </div>
    }
}
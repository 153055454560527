import { IResource, IResourceGroup } from '../../types';
import { ResourceTypeType } from '../../types/common';
import ControllerRequest from './_controller.base';

export class ResourceController extends ControllerRequest {
    constructor() {
        super('resource');
    }

    getResourceGroupByType(type: ResourceTypeType, groupedBy: string): Promise<IResourceGroup[]> {
        return this.execGET(`/group/resourceType/${type}?groupedBy=${groupedBy}`);
    }

    // getResourceGroupByType(type: ResourceTypeType, groupedBy: string): Promise<IResourceGroup[]> {
    //     return this.execGET(`/type/${type}/by/${groupedBy}`);
    // }

    getResourceGroupResources(type: ResourceTypeType, groupId: string): Promise<IResource[]> {
        return this.execGET(`/group/resourceType/${type}/groupId/${groupId}/resources`);
    }
}
import React from 'react';
import * as S from './styles';

export class Subtitle extends React.Component<{
    children?: React.ReactNode
}> {
    render() {
        return (
            <S.Subtitle>
                {this.props.children}
            </S.Subtitle>
        )
    }
}
import styled from 'styled-components';
import { classnames, TTextColor } from 'tailwindcss-classnames';

export const Container = styled.div.attrs({
    className: classnames('relative', 'pt-2', 'pb-0', 'lg:pb-1', 'px-4', 'md:px-0')
})``;

export const Subtitle = (color: string) =>  styled.p.attrs({
    className: classnames('relative', color as TTextColor)
})`
    font-size: 1.1rem;
    font-weight: 200;
`;
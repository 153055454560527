import { faDownload } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { IFile } from '../../../types/common';
import { ResourceSidebar, ResourceSidebarItem } from '../../molecules';

interface IProps {
    title: string;
    subtitle: string;
    files?: IFile[];
    goBackAction: () => void;
}

export class SingleGuideSidebar extends React.Component<IProps> {
    render() {
        return <ResourceSidebar
            goBackAction={this.props.goBackAction}
            title={this.props.title}
            subtitle={this.props.subtitle}>
            <ResourceSidebarItem theme="dark" title="Descargas" icon={faDownload}>
                <ul>
                    {
                        Array.isArray(this.props.files) && !!this.props.files.length ?
                            this.props.files.map(file => {
                                return <li key={file.id}>
                                    <a className="hover:font-semibold text-white" target="_blank" rel="noreferrer" href={file.url}>
                                        {file.name}
                                    </a>
                                </li>
                            }) :
                            <span className="italic">No hay documentos para esta guía.</span>
                    }
                </ul>
            </ResourceSidebarItem>
        </ResourceSidebar>
    }
}
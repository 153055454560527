import React, { FC, useEffect, useState } from 'react'
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { useNavigate, useLocation } from 'react-router-dom'
import { Button } from '../../components/atoms'
import { ContentWrapper } from '../../components/containers'
import { BackgroundPageTitle } from '../../components/molecules'
import { AdminUsersSearchBar } from '../../components/molecules/Users'
import { IUserDetailForAdmin } from '../../dto/user/user-details-for-admin.dto'
import { AdminUsersFilter, useUserDetailsForAdmin } from '../../hooks/Users'
import { withLayout } from '../../hocs/withLayout'
import { withAuth } from '../../hocs'
import { UserStatusEnum } from '../../enums'
import { PillText } from '../../components/molecules/PillText'

export const UserDetailsForAdmin: FC<any> = () => {
    const { search } = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useState('');

    const {
        status,
        data,
        hasItems,
        fetchData,
        approveUserRequest,
        denyUserRequest,
        cleanData
    } = useUserDetailsForAdmin();

    const onApprove = async (e: any, user: IUserDetailForAdmin) => {
        e.preventDefault();
        await approveUserRequest(user.id);
        user.isPendingVerification = false;
    }

    const onDeny = async (e: any, user: IUserDetailForAdmin) => {
        e.preventDefault();
        await denyUserRequest(user.id);
        user.isPendingVerification = false;
    }



    useEffect(() => {
        if (status !== 'none') {
            return;
        }
        const searchParams = new URLSearchParams(search);
        const firstname = searchParams.get('firstname') || '';
        const lastname = searchParams.get('lastname') || '';
        const statusParam = searchParams.get('status') || '';

        console.log('UseEffect in action', statusParam);

        const filterFromSearch: AdminUsersFilter = {
            firstname,
            lastname,
            status: statusParam as any
        };
        fetchData(filterFromSearch);

        return () => {
            cleanData();
        }
    }, [searchParams]);

    useEffect(() => {

        navigate({
            pathname: `/gestion-usuarios`,
            search: searchParams,
        });
    
      return () => {
        
      }
    }, [data])
    

    return (
        <div className="flex-wrap">
            <BackgroundPageTitle imageURL="/assets/img/temp/temp-2.jpg" title="Gestión de solicitudes" heigthSize="sm" />

            <ContentWrapper>
                <div className="my-4">
                    <AdminUsersSearchBar onSearchChanged={(filter) => {
                        let search = '';
                        if (filter.firstname) {
                            search += `?firstname=${filter.firstname}&`;
                        }
                        if (!!filter.lastname && !!filter.lastname.length) {
                            search += `lastname=${filter.lastname}&`;
                        }
                        if (filter.status) {
                            search += `status=${filter.status}`;
                        }

                        setSearchParams(search === '?' ? '' : search);
                    }} />
                </div>

                <div className="w-full">
                    <table className="table table-auto min-w-full divide-y divide-gray-50 dark:divide-gray-700 ring-1 ring-gray-700">
                        <thead className="bg-gray-50 dark:bg-gray-300">
                            <tr>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gpas-700 uppercase tracking-wider">
                                    Nombre de la persona
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gpas-700 uppercase tracking-wider">
                                    Rol
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gpas-700 uppercase tracking-wider">
                                    Estado
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gpas-700 uppercase tracking-wider">
                                    Aprobación
                                </th>
                            </tr>
                        </thead>

                        <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-700">
                            {
                                status === 'fetched' && hasItems &&
                                data.map(user => (
                                    <tr key={user.email}>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="flex items-center">
                                                <div className="flex-shrink-0 h-10 w-10">
                                                    <img className="h-10 w-10 rounded-full" src={user.avatarURL} alt={user.email} />
                                                </div>
                                                <div className="ml-4">
                                                    <div className="text-sm font-medium text-gray-900 dark:text-white">
                                                        {user.firstname} {user.lastname}
                                                    </div>
                                                    <div className="text-sm text-gray-500 dark:text-gray-100">
                                                        {user.email}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>{user.roles.map(r => r.description).join(', ')}</td>
                                        <td>
                                            {
                                                (user.status === UserStatusEnum.ENABLED) ?
                                                    <PillText text={user.statusName} type='success' /> :
                                                    
                                                    user.status === UserStatusEnum.DISABLED ?
                                                    <PillText text={user.statusName} type='danger' /> :
                                                    
                                                    user.status === UserStatusEnum.VERIFICATION ?
                                                    <PillText text={user.statusName} type='warning' /> :
                                                    
                                                    <PillText text={user.statusName} />
                                            }</td>
                                        <td>
                                            <div className="flex space-x-2 mx-2 align-middle">
                                                {
                                                    !!user.isPendingVerification ?
                                                        <>
                                                            <Button onClick={(e) => onApprove(e, user)} color="success" size="sm" icon={faCheckCircle}>Aceptar</Button>
                                                            <Button onClick={(e) => onDeny(e, user)} color="dangerous" size="sm" icon={faTimesCircle}>Rechazar</Button>
                                                        </> :
                                                        <PillText text={'Ya aprobado'} />
                                                }
                                            </div>
                                        </td>
                                    </tr>

                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </ContentWrapper>
        </div >
    )
}

export const UserDetailsForAdminPage = withLayout(withAuth(UserDetailsForAdmin, { adminOnly: true }))
import React from 'react';

export class MarkdownContent extends React.Component<{
    children?: React.ReactNode;
}> {
    render() {
        return (<div className="relative markdown-context">
                {this.props.children}
            </div>)
    }
}
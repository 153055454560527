import React, { useContext } from 'react';
import { AuthContext } from '../../../contexts'
import { withLayout } from '../../../hocs/withLayout';
import { withAuth } from '../../../hocs';

export const SignOut: React.FC<any> = () => {
    const authCtx = useContext(AuthContext);

    if (authCtx.isAuthenticated) {
        authCtx.signOut().then(() => {});
    }

    return <>
        <h2>Cerrando sesión...</h2>
        </>
}

export const SignOutPage = withLayout(withAuth(SignOut))
import React, { useContext } from 'react';
import { IProps } from './types';
import { UserProfile } from '..';
import { Container, Content } from './styles';
import { NavLink } from '../../atoms';
import { AuthContext } from '../../../contexts';

export const NavbarActions: React.FC<IProps> = ({ ...props }: IProps) => {
    const authCtx = useContext(AuthContext);
    return (
        <Container>
            <Content>
                {
                    !authCtx.isAuthenticated &&
                    <>
                        <NavLink to="/bienvenidos" color="white">Iniciar sesión</NavLink>
                    </>
                }
                {
                    authCtx.isAuthenticated && authCtx.user &&
                    <div className="relative flex space-x-1">
                        <UserProfile username={authCtx.user.email} firstname={authCtx.user.firstname} imageURL={authCtx.user?.avatarURL} />
                    </div>
                }
            </Content>
        </Container >
    );
}
import React from 'react';
import * as S from './style';

interface IProps {
    title: string;
    subtitle?: string;
    subtitleColor?: string;
    color?: string;
}

export class PageTitle extends React.Component<IProps> {
    render() {
        const colorText = this.props.color || 'text-gpas-100'
        // const subtitleTextColor = this.props.subtitleColor || 'text-gray-700';

        // const Subtitle = S.Subtitle(subtitleTextColor);
        return (
            <S.Container>
                <h1 className={` ${colorText} dark:text-white relative font-bold text-4xl md:text-5xl pb-0`}>
                    {this.props.title}
                </h1>
                {
                    this.props.subtitle &&
                    <p className="relative leading-tight text-sm lg:text-sm font-bold pt-2 text-gpas-400 dark:text-gray-300 uppercase subpixel-antialiased">
                        {this.props.subtitle}
                    </p>
                }
            </S.Container>
        )
    }
}

import React from 'react';
import './styles/tailwind.css';
import './styles/css/main.css';
import './styles/scss/globals.scss';
import App from './app/App';
import 'moment/locale/es';
import { createBrowserHistory } from "history";
import { createRoot } from 'react-dom/client';

const history = createBrowserHistory();


const container = document.getElementById('root') as Element;
const root = createRoot(container);  // createRoot is the new API in React 18
root.render(<App />);


// ReactDOM.render(
//   <React.StrictMode>
//     <Router history={history}>
//       <App />
//     </Router>
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import styled from 'styled-components';
import { classnames, TBorderColor, TTextColor } from 'tailwindcss-classnames';
import { TTheme } from '../../../types/common';

export const ResourceContainer = styled.div.attrs({
    className: classnames('flex-wrap', 'pb-4')
})``;

export const ResourceItem = styled.div.attrs({
    className: classnames('flex-wrap', 'text-sm', 'border-b', 'border-gray-700' as TBorderColor)
})``;

const ResourceItemHeaderBase = classnames('flex', 'space-y-1', 'items-center');
export const ResourceItemHeaderContainer = (theme: TTheme) => styled.div.attrs({
        className: classnames(ResourceItemHeaderBase, theme === 'light' ? 
            'dark:text-gpas-300' as TTextColor :
            'text-white')
})``;

export const ResourceItemHeader = styled.h4.attrs({
    className: classnames('py-0', 'pt-2', 'font-bold')
})`padding-top: 0px`;

export const FileItem = styled.div.attrs({
    className: classnames('flex')
})``;

export const FileItemEmptyMessage = styled.span.attrs({
    className: classnames('italic')
})``;
import React from 'react';
import * as S from './styles';
import * as T from './types';
export const UserPicture: React.FC<T.IProps> = ({
    imageURL,
    username,
    ...props}) => {
    return (
        <S.Container>
            <S.Image src={imageURL} alt={username} />
        </S.Container>
    )
}
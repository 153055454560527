import styled from 'styled-components';

export const Input = styled.input`
appearance: none;
background-color: #ffffff;
border-color: #d2d6dc;
border-width: 1px;
border-radius: 0.375rem;
padding-top: 0.5rem;
padding-right: 0.75rem;
padding-bottom: 0.5rem;
padding-left: 0.75rem;
font-size: 1rem;
line-height: 1.5;
`;
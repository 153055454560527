import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Suspense, useEffect, useState } from 'react';
import { PageTitle } from '../../../components';
import { ContentWrapper, ResourceContainer } from '../../../components/containers';
import { ResourceCardItem } from '../../../components/molecules/ResourceCardItem';
import { GuidesSideSearch } from '../../../components/organisms/Guides/GuidesSideSearch/GuidesSideSearch';
import { GuideApiService } from '../../../lib/controllers';
import { IGuide } from '../../../types';
import { withLayout } from '../../../hocs/withLayout';
import { withAuth } from '../../../hocs';
interface IProps {}

const guideApiService = new GuideApiService();

export const Guides: React.FC<IProps> = ({ ...props }) => {
    const [guides, setGuides] = useState<IGuide[]>([]);
    const onSearchChanged = (): void => {};

    useEffect(() => {
        guideApiService.getList().then(all => setGuides(all));
    }, [setGuides])

    return (
        <>
        <ContentWrapper>
            <PageTitle title="Guías" subtitle="Acá encontrarás todas las guías y recursos necesarios." />

            <div className="xl:flex-wrap pt-4">
                {/* <div className="w-full mb-2">
                    <GridSearchBar onAction={onAction} title="¿Qué estudio estás buscando?" options={options} />
                </div> */}

                <div className="flex h-screen overflow-hidden pb-10">
                    <div className="xl:w-2/6 xl:pr-4">
                        <GuidesSideSearch onSearchChanged={onSearchChanged} />
                    </div>
                    <div className="xl:w-5/6 flex-1 overflow-y-scroll">
                        <Suspense fallback={<FontAwesomeIcon icon={faSpinner} spin />}>
                            <ResourceContainer>
                                {
                                    guides.map(guide => 
                                        <ResourceCardItem key={guide.slug} size="md" to={`/guias/${guide.slug}`} {...guide} />)
                                }
                            </ResourceContainer>
                        </Suspense>
                    </div>
                </div>
            </div>
        </ContentWrapper>
        </>
    )
}

export const GuidesPage = withLayout(withAuth(Guides))
import styled from 'styled-components';
import { classnames } from 'tailwindcss-classnames';

export const ProfileContainer = styled.div.attrs({
    className: classnames('flex')
})``;

export const Sidebar = styled.div.attrs({
    className: classnames('xl:w-1/4')
})``;

export const Content = styled.div.attrs({
    className: classnames('xl:w-3/4')
})``;
import React, { useEffect } from 'react';
import qs from 'query-string';
import { useLocation } from 'react-router-dom';
import { withLayout } from '../../../hocs/withLayout';

const SigninCallbackPC: React.FC<any> = ({ ...props }) => {
    const location = useLocation();
    let params: any;

    useEffect(() => {
        if (location && location.search) {
            params = qs.parse(location.search);
        }
        return () => {}
    }, [location]);


    return (
        <>
            <span>
                Params: {JSON.stringify(params)}
            </span>
        </>
    )
}

export const SigninCallbackPCPage = withLayout(SigninCallbackPC);
import { ICycle } from '../../types';
import ControllerRequest from './_controller.base';

export class CycleController extends ControllerRequest {
    constructor() {
        super('cycle');
    }

    getAll(): Promise<ICycle[]> {
        return this.execGET('/');
    }
}
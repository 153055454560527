import React, { Suspense, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ContentWrapper, Markdown } from '../../../components/containers';
import { BackgroundPageTitle, MarkdownContent } from '../../../components/molecules';
import { SingleGuideSidebar } from '../../../components/organisms';
import { GuideApiService } from '../../../lib/controllers';
import { IGuide } from '../../../types';

const guideApiService = new GuideApiService();

export const SingleGuidePage: React.FC<any> = ({ ...props }) => {
    const params = useParams();
    const [guide, setGuide] = useState<IGuide>({} as any);
    const navigate = useNavigate();
    const getData = async (slug: string) => {
        if (guide && guide.title) {
            return;
        }
        const data = await guideApiService.getSingleGuide(slug);
        setGuide(data);
    }

    useEffect(() => {
        getData(params.slug as string).then();
    }, [params])


    return (
        <Suspense fallback={<span>Cargando guías...</span>}>
            <div className="h-full overflow-hidden">
            <BackgroundPageTitle imageURL={guide.imageURL} title={guide.title} subtitle={guide.subtitle} />
            <ContentWrapper>
                <div className="sm:flex-wrap xl:flex flex-1 mt-6 h-screen ">
                    <div className="sm:w-full xl:w-3/4 bg-white xs:px-6 xl:px-10 xl:py-6 shadow-sm rounded-md ">
                        <div className="overflow-x-scroll overflow-y-visible">
                        <MarkdownContent>
                            <Markdown content={guide.content} />
                        </MarkdownContent>
                        </div>
                    </div>

                    <div className="xl:w-1/4 flex-1 sticky">
                        <SingleGuideSidebar goBackAction={() => {navigate(-1)}} title="Sobre esta guía" subtitle={guide.subtitle} files={guide.files} />
                    </div>
                </div>
            </ContentWrapper>
            </div>
        </Suspense>
    )
}
import { faClock, faUser, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Component } from 'react';
import Moment from 'moment';
import { ISupportTicketComment } from '../../../../types';

interface IProps {
    comment: ISupportTicketComment
}

export class QuestionComment extends Component<IProps> {
    render() {
        const Item = (data: {text: string, icon: IconDefinition}) => <div className="flex items-center space-x-1 text-xs">
            <FontAwesomeIcon icon={data.icon} className="text-gray-300" />
            <span className="text-gray-500">{data.text}</span>
        </div>;

        return (
            <div className="relative w-full px-4 py-3 flex space-x-2 border-gray-100"  style={{borderBottom: '1px solid'}}>
                <img className="rounded-full h-8 w-8 top-0" width={30} src={this.props.comment.byUser.avatarURL} alt={this.props.comment.byUser.username} />
                <div className="flex-wrap space-x-0">
                    <p className="text-sm pb-1">{this.props.comment.message}</p>
                    <div className="flex items-center space-x-2">
                        <Item text={Moment(this.props.comment.createdAt).fromNow()} icon={faClock} />
                        <Item text={`${this.props.comment.byUser.firstname} ${this.props.comment.byUser.lastname}`} icon={faUser} />
                    </div>
                </div>
            </div>
        )
    }
}
import styled from 'styled-components';

export const HeaderContainer = styled.div.attrs({
    className: 'flex bg-purple-800 border-b border-gray-200 fixed top-0 inset-x-0 z-100 h-24 items-center'
})`
    position: static;
`;

export const Content = styled.div.attrs({
    className: 'w-full max-w-screen-xl relative mx-auto px-6'
})`
    padding: 0 20px;
    flex-grow: 1;
`;

export const ItemsContainer = styled.div.attrs({
    className: 'flex items-center -mx-6'
})`flex-grow: 1;`;
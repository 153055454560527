import React from 'react'
import Moment from 'moment';
import { Link } from 'react-router-dom';
import { faBook, faClock, faVideo } from '@fortawesome/free-solid-svg-icons'
import { ImageCardBox, VideoCardBox } from '../../molecules';
import { Content } from './styles'
import { IProps } from './types'

export class NewsCardBox extends React.Component<IProps> {
    render() {
        // const fromDate = this.props.news.startsAt ? <Moment format="DD/MM/YYYY hh:mm">{this.props.news.startsAt}</Moment> : null;
        // const toDate = this.props.news.endsAt ? <Moment format="DD/MM/YYYY hh:mm">{this.props.news.endsAt}</Moment> : null;
        const cardOptions = [{
            title: 'Fecha',
            value: Moment(this.props.news.startsAt).locale('es').fromNow(),
            icon: faClock
        }];

        if (this.props.news.study) {
            cardOptions.push({
                title: 'Estudio',
                value: this.props.news.study.title,
                icon: faBook
            })
        }

        if (this.props.news.externalVideoURL) {
            cardOptions.push({
                title: 'Video',
                value: 'Video',
                icon: faVideo
            })
        }

        const CardContainer = () => <ImageCardBox shape="hori"
            size={this.props.size}
            imageURL={this.props.news.imageURL}
            title={this.props.news.title}
            subtitle={this.props.news.subtitle}
            cardOptions={cardOptions}>
            <Content>
                <p className="xl:text-normal">{this.props.news.subtitle}</p>
            </Content>
        </ImageCardBox>;

        if (this.props.news.showAs === 'video' && !!this.props.news.videoFileURL) {
            return <VideoCardBox size={this.props.size} imageURL={this.props.news.imageURL} videoURL={this.props.news.videoFileURL} >
                <CardContainer />
            </VideoCardBox>
        }

        return (
            <Link to={this.props.to} className="child">
                <CardContainer />
            </Link>
        )
    }
}
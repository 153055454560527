import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'

interface IProps {
    title: string;
    url: string;
    icon: IconDefinition
}

export class HeaderOptionLink extends Component<IProps> {
    render() {
        return (
            <Link to={this.props.url}>
                <span className="items-center flex text-white text-xs md:text-sm focus:bg-gpas-500 rounded-md ">
                    <span className="transition-all hover:underline">
                        {this.props.title}
                    </span>
                </span>
            </Link>
        )
    }
}

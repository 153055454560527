import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { ContentWrapper } from '../../components/containers'

export const WelcomeWaitingForApproval: React.FC<any> = ({...props}) => {
    return (
        <ContentWrapper>
            <div className="xl:flex-col -mx-6 xl:pt-10">
                <div className="flex-1 bg-gpas-100 ring-2 ring-gpas-300 px-10 py-12 lg:mt-20 rounded-md shadow-sm">
                    <h1 className="tracking-tight space-x-2 sm:text-4xl text-gpas-700 md:text-5xl xl:text-5xl leading-tight pb-2">
                        <FontAwesomeIcon icon={faCheckCircle} size={"1x"} className="mr-4" />
                        ¡Gracias por registrarte!
                    </h1>
                    <span className="text-2xl sm:block text-gpas-700 font-medium">
                        Pronto serás te darán una respuesta a la solicitud.
                    </span>
                    <p className="mt-6 ">
                        Ya las personas encargadas están enteradas, por favor esperá un correo de vuelta.
                    </p>
                </div>
            </div>

        </ContentWrapper>
    )
}
import React from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import * as S from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IProps {
    title?: string; 
    icon: IconDefinition;
    children?: React.ReactNode,
}

export class CardOptionItem extends React.Component<IProps> {
    render() {
        return (
            <S.OptionsItem>
                <FontAwesomeIcon icon={this.props.icon} className="text-xs text-gpas-100" />
                <div className="flex-wrap flex-shrink-0">
                    {this.props.children}
                </div>
            </S.OptionsItem>)
    }
}
import styled from 'styled-components';
import { classnames } from 'tailwindcss-classnames';

export const ButtonLinkStyle = styled.a.attrs({
    className: classnames(
        'block', 
        'underline', 
        'cursor-pointer', 
        'bg-gpas-100' as any, 
        'dark:bg-gpas-200' as any, 
        'hover:bg-gpas-700' as any, 
        'hover:text-white',
        'transition-all',
        'ease-in-out',
        'rounded-sm', 
        'text-sm',
        'px-2', 
        'py-2',
    )
})``;

export const ButtonStyle = styled.a.attrs({
    className: classnames(  
        'block', 
        'space-x-1', 
        'cursor-pointer', 
        'bg-gpas-700' as any, 
        'text-white', 'px-2', 'py-2', 'rounded-sm', 'no-underline', 'hover:bg-gpas-800' as any)
})``;
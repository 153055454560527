import { jwtDecode } from 'jwt-decode';
import { UserRoleEnum } from '../../enums';
import { LocalStorageHelper } from '../../helpers/storage';
import { AuthController } from '../controllers';

const REFRESH_TOKEN_KEY = 'pas-refresh-token';
const ACCESS_TOKEN_KEY = 'pas-access-token';
const authCtrl = new AuthController();
let jwtToken: string = '';

export const hasAccessToken = (): boolean => {
    return !!LocalStorageHelper.getValue(ACCESS_TOKEN_KEY);
}

export const refreshToken = async (): Promise<void> => {
    const refreshToken = LocalStorageHelper.getValue(REFRESH_TOKEN_KEY);
    if (!refreshToken) {
        return;
    }

    try {
        const res = await authCtrl.refreshToken({refreshToken});
        LocalStorageHelper.setValue(ACCESS_TOKEN_KEY, res.access_token);
        jwtToken = res.access_token;
    } catch (error) {
        console.warn('Cannot refresh token, session removed');
        LocalStorageHelper.removeValue(REFRESH_TOKEN_KEY);
        LocalStorageHelper.removeValue(ACCESS_TOKEN_KEY);
    }
}

export const signin = async (data: { state: string, token: string, source: 'planning-center'}) => {
    jwtToken = data.token;
    const userSession = await authCtrl.token(data);
    LocalStorageHelper.setValue(REFRESH_TOKEN_KEY, userSession.refresh_token);
    LocalStorageHelper.setValue(ACCESS_TOKEN_KEY, userSession.access_token);
    jwtToken = userSession.access_token;
}

export const signOut = async () => {
    // tomar jwt y refresh para enviarlo al api 
    const jti = LocalStorageHelper.getValue(REFRESH_TOKEN_KEY) as string;
    const jwt = LocalStorageHelper.getValue(ACCESS_TOKEN_KEY) as string;
    authCtrl.signOut({ 
        jwt,
        refreshToken: jti
     });
    LocalStorageHelper.removeValue(ACCESS_TOKEN_KEY);
    LocalStorageHelper.removeValue(REFRESH_TOKEN_KEY);
}

export const isTokenExpired = (): boolean => {
    const accessToken = getAccessToken();
    if (!accessToken) {
        return false;
    }
    const payload = jwtDecode(accessToken);
    return !!payload;
}

export const getAccessToken = (): string => {
    if (!!jwtToken) {
        return jwtToken;
    }
    return LocalStorageHelper.getValue(ACCESS_TOKEN_KEY) || '';
}

export const getAccessTokenUserRole = (): UserRoleEnum|UserRoleEnum[]|undefined => {
    const acc = getAccessToken();
    if (!acc) {
        return undefined;
    }
    const decoded: any = jwtDecode(acc);
    return decoded.roles;
}
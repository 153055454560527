import React from 'react';
import { IProps } from './types'

export const MenuItem: React.FC<IProps> = (props: IProps) => {
    if (props.type === 'button-link') {
        return (
            <li className="inline-block pas-mx-auto">
                <a className="p-0 pas-h-20 pas-px-2" href={props.c2aURL}>
                    {props.text}
                </a>
            </li>
        );
    }

    return null;
}
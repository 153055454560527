import styled from 'styled-components'
import { classnames } from 'tailwindcss-classnames'

const base = classnames(
    'px-0', 
    'pb-0',
    'rounded-md',
    'bg-white',
    'dark:bg-gray-800' as any,
    'border',
    'border-gray-700',
    'flex-wrap',
    'relative',
    'bg-title' as any);
export const BoxContainer = (rounded: string) => styled.div.attrs({
    className: classnames(base, `rounded-${rounded}` as any)
})``;


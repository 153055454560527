import React from "react";
import { IProps } from "./types";

export const ErrorMessage: React.FC<IProps> = ({ errors }: IProps) =>
  errors && errors.length ? (
    <div className="px-2">
      {errors.map((error, index) => (
        <span className="text-red-600" key={index}>{error.message}</span>
      ))}
    </div>
  ) : null;

import styled from 'styled-components'
import { classnames } from 'tailwindcss-classnames'

export const Container = styled.div.attrs({
    className: classnames('flex', 'items-center', 'text-white')
})``;

export const OptionsContainer = styled.div.attrs({
    className: classnames('fixed', 'bg-gray-300', 'px-12', 'h-auto', 'inline-block')
})`
`;
// top: 0px;
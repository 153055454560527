import React, { useEffect, useState } from 'react';
import { IUser } from '../../types';
import { AuthServices } from '../../lib/services';
import { IAuthContext } from './types';
import { UserController } from '../../lib/controllers/user.ctrl';
import { UserRoleEnum } from '../../enums';

export const AuthContext = React.createContext<IAuthContext>({
    isAuthenticated: false,
    isAuthLoaded: false,
    isUserAdmin: false,
    signinFromTempToken: async () => {},
    initAppSession: async (): Promise<void> => {},
    signOut: async (): Promise<void> => {}
});

export const AuthProvider = ({ children }: any) => {
    const [user, setUser] = React.useState<IUser>();
    const [userRole, setUserRole] = React.useState<UserRoleEnum>();
    const [isUserAdmin, setUserIsAdmin] = React.useState(false);
    const [jwtToken, setJwtToken] = useState<string|null>('');
    const [isAuthenticated, setIsAuthenticated] = React.useState(false);
    const [isAuthLoaded, setIsAuthLoaded] = React.useState(false);

    useEffect(() => {
        loadUserSession();
    }, [jwtToken]);

    const loadUserSession = async () => {
        setIsAuthenticated(false);
        if (!jwtToken) {
            const storedAccessToken = AuthServices.getAccessToken();
            if (!storedAccessToken) {
                setIsAuthLoaded(true);
                setUserPermissions();
                return;
            }
            setJwtToken(storedAccessToken);
        }
        
        const userService = new UserController();
        const user = await userService.getMe();
        setUser(user);
        setUserPermissions();
        setIsAuthenticated(true);
        setIsAuthLoaded(true);
    }

    const setUserPermissions = () => {
        const role = AuthServices.getAccessTokenUserRole();
        if (role instanceof Array && !!role.length) {
            if (role.includes(UserRoleEnum.ADMIN)) {
                setUserRole(UserRoleEnum.ADMIN)
                setUserIsAdmin(true);
                return;

            } else if (role.includes(UserRoleEnum.MEMBER)) {
                setUserRole(UserRoleEnum.MEMBER)
            }
            setUserRole(role[0]);
        }
        else {
            setUserRole(UserRoleEnum.MEMBER);
        }
    }

    const signinFromTempToken = async (data: { state: string, source: 'planning-center', token: string, status: string }): Promise<any> => {
        setIsAuthenticated(false);
        try {

            if (data.status === 'user_waiting_for_approval') {
                return;
            }
            await AuthServices.signin(data);
            const currentJwtToken = AuthServices.getAccessToken();
            setJwtToken(currentJwtToken);
        } catch (error) {
            setIsAuthenticated(false);
        }
    }

    const initAppSession = async (): Promise<void> => {
        setIsAuthLoaded(false);
        setJwtToken(AuthServices.getAccessToken());
    }
    
    const signOut = async () => {
        await AuthServices.signOut();
        setJwtToken(null);
    }

    return <AuthContext.Provider value={{
        // session,
        user,
        isAuthenticated,
        isAuthLoaded,
        isUserAdmin,
        userRole,
        signinFromTempToken,
        initAppSession,
        signOut
    }}>
        {children}
    </AuthContext.Provider>
}
import React from 'react';
import * as S from './styles';

interface IProps {
    type?: 'grid'|'flex';
    children?: React.ReactNode;
}

export class ResourceContainer extends React.Component<IProps> {
    getGrindContent() {
        return S.GridContent();
    }

    getFlexContent() {
        return S.FlexContent();
    }

    render() {
        const type = this.props.type || 'flex';
        const Content = type === 'flex' ? 
            this.getFlexContent() : this.getGrindContent();
        
        return (
            // <S.Container>
                <Content>
                    {this.props.children}
                </Content>
            // </S.Container>
        )
    }
}
import { ISupportTicketCategory } from '../../types';
import ControllerRequest from './_controller.base';

export class SupportTicketCategoryController extends ControllerRequest {
    constructor() {
        super('support-ticket-category');
    }

    fetchAll(): Promise<ISupportTicketCategory[]> {
        return this.execGET('/');
    }
}
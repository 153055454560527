import styled from 'styled-components';

export const Content = styled.a.attrs({
    className: "lg:w-1/4 xl:w-1/5 pl-6 pr-2 lg:pr-2 flex items-center"
})``;

export const LogoImage = styled.img`
    heigth: 60px;
    padding: 8px 8px 8px 0;
    width: 60px;
`;

export const AppName = styled.span.attrs({
    className: 'text-white text-bold visible sm:invisible md:visible lg:visible '
})`
    font-size: 14px;
    font-weight: 400;
`;
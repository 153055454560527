import { IPasGroupCategory } from '../../types/pas-group-category.type';
import ControllerRequest from './_controller.base';

export class PasGroupCategoryController extends ControllerRequest {
    constructor() {
        super('pas-group-category');
    }

    getAll(): Promise<IPasGroupCategory[]> {
        return this.execGET('/');
    }
}
import styled from 'styled-components';
import { classnames } from 'tailwindcss-classnames';

const classBase = classnames(
    'border-gray-300', 'rounded-md', 'shadow-sm', 'my-2',
    'focus:border-gray-400',
    'px-4', 'py-2', 
    'block', 'inline-block',
    'w-full',
    'bg-gray-100',
    'focus:border-gray-100',
    'transition-shadow',
    'focus:shadow-md');

export const Input = styled.input.attrs({
    className: classnames(classBase)
})`
    border: 1px solid;
`

export const InputError = styled.input.attrs({
    className: classnames(classBase, 'border-red-200')
})`
    border: 2px solid;
`

export const SpanError = styled.span.attrs({
    className: classnames('text-red-400', 'text-sm')
})``;
import React from 'react';
import { ContentWrapper } from '../../containers';
import * as S from './styles';
export class Footer extends React.Component {
    render() {
        return (
            <S.Container>
                <ContentWrapper>
                    <div className="flex text-gray-500 relative place-content-start my-auto items-center space-x-1">
                        <S.GPLogoImage src="/assets/img/logo-white.png" alt="Grupos Pas" />
                    </div>
                </ContentWrapper>
            </S.Container>
        )
    }
}
import { IGuide } from '../../types';
import ControllerRequest from './_controller.base';

export class GuideApiService extends ControllerRequest {
    constructor() {
        super('guide');
    }

    getList(): Promise<IGuide[]> {
        return this.execGET('/');
    }

    getSingleGuide(slug: string): Promise<IGuide> {
        return this.execGET(`/${slug}`);
    }
}
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface IProps {
    icon: IconDefinition;
}

export class Icon extends React.Component<IProps> {
    render() {
        return <div className="bg-gpas-100 rounded-full h-auto px-2 py-1">
            <FontAwesomeIcon icon={this.props.icon} className="text-gpas-700" style={{fontSize: '1.2rem'}} />
        </div>
    }
}
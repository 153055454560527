import React from 'react';
import { Link } from 'react-router-dom';
import { LogoImage } from './styles';

export const NavbarBrand: React.FC<any> = () => {
    return (
        <Link to="/" className="lg:w-1/4 xl:w-1/5 pl-6 pr-2 lg:pr-2 flex items-center">
                <LogoImage src="/assets/img/logo-white.png" alt="" />
        </Link>
    )
}
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { ButtonLinkStyle, ButtonStyle } from './styled';
import { IProps } from './types'

export const LinkButton: React.FC<IProps> = ({
    children,
    size,
    url,
    buttonStyle = 'link',
    ...props
}: IProps) => {

    const ButtonContainer = buttonStyle === 'link' ?
        ButtonLinkStyle : ButtonStyle;

    return (
        <ButtonContainer href={url}>
            {
                props.icon &&
                <FontAwesomeIcon icon={props.icon} className="mr-2" />
            }
            {children}
        </ButtonContainer>
    )
}

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import React from 'react';

interface IProps {
    title: string;
    icon: IconDefinition;
    children?: React.ReactNode;
}

export class SideSearchItem extends React.Component<IProps> {
    render() {
        return (
            <div className="flex-wrap py-3">
                <div className="flex relative items-center place-items-center pb-1">
                    <span className="text-lg text-gpas-700 dark:text-white leading-5 font-semibold pb-1" >
                        {this.props.title}</span>
                </div>
                <div className="relative">
                    {this.props.children}
                </div>
            </div>)
    }
}
import React, { useEffect, useState } from 'react'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, useParams } from 'react-router-dom';
import { Markdown } from '../../../components/containers'
import { BackgroundPageTitle, MarkdownContent, VideoPlayer } from '../../../components/molecules';
import { SingleStudySidebar } from '../../../components/organisms';
import { useStudiesHook } from '../../../hooks/Study/StudiesHook';
import { IStudy } from '../../../types/study.type';
import { withLayout } from '../../../hocs/withLayout';
import { withAuth } from '../../../hocs';

export const SingleStudy = () => {
    const params = useParams();
    const { currentStudy, getSingleStudy, status, clearSingleStudySearch } = useStudiesHook();
    const navigate = useNavigate();
    const [showIntro, setShowIntro] = useState(true);
    const [backgroundSubtitle, setBackgroundSubtitle] = useState('');

    useEffect(() => {
        if (status === 'fetching' || status === 'fetched') {
            return;
        }

        getSingleStudy(params.slug as string)
            .then((study: IStudy) => {
                setBackgroundSubtitle(`${study.pasGroupCategory?.name}, ${study.cycle?.title}`);
            });
        return () => {
            clearSingleStudySearch();
        }
    }, [params]);


    if (!currentStudy) {
        return (<>
            <span>Cargando estudio...</span>
        </>)
    }

    return (
        <>
            <div className="min-h-full">
                <BackgroundPageTitle imageURL={currentStudy.imageURL} title={currentStudy.title} subtitle={backgroundSubtitle} heigthSize="sm" />
                <div className="flex pt-2 mx-1 md:mx-auto max-w-screen-lg text-opacity-25">
                    <div className="flex flex-col md:flex-row mt-6 pb-6 space-x-0 md:space-x-3 space-y-4 md:space-y-0">
                        <div className="flex-grow flex flex-col md:overflow-y-scroll space-y-3 no-scrollbar">
                            {
                                currentStudy.introContent && showIntro &&
                                <div className="bg-gpas-100 dark:bg-gray-800 text-gpas-700 dark:text-white px-6 py-4 rounded-md shadow-sm border-1 border-gray-700">
                                    <Markdown content={currentStudy.introContent} />
                                    <button onClick={e => {
                                        e.preventDefault();
                                        setShowIntro(false);
                                    }} className="bg-gpas-700 dark:bg-gpas-700 text-white dark:text-gray-100 px-3 py-2 flex items-center flex-row space-x-2 mt-4 rounded-md shadow-sm hover:bg-gpas-900">
                                        <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
                                        Entendido
                                    </button>
                                </div>
                            }
                            <div className="bg-gray-50 dark:bg-gray-900 border border-gray-700 rounded-md px-3 md:px-6 xl:py-3 dark:text-white">
                                {
                                    currentStudy.externalVideoURL &&
                                    <div className="py-4">
                                        <VideoPlayer videoURL={currentStudy.externalVideoURL} />
                                    </div>
                                }

                                <MarkdownContent>
                                    <div className="text-opacity-50">
                                        <Markdown content={currentStudy.content} />
                                    </div>
                                </MarkdownContent>
                            </div>
                        </div>
                        <div className="flex-initial md:sticky">
                            <SingleStudySidebar
                                goBackAction={() => navigate(-1)}
                                onHideAction={(hidde: boolean) => {

                                }}
                                title="Información"
                                subtitle={currentStudy.subtitle}
                                startAt={currentStudy.startAt}
                                endAt={currentStudy.endAt}
                                pasGroupCategory={currentStudy.pasGroupCategory}
                                cycle={currentStudy.cycle}
                                files={currentStudy.files} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export const SingleStudyPage = withLayout(withAuth(SingleStudy))
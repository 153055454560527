import styled from 'styled-components';
import {classnames} from 'tailwindcss-classnames'

// export const Container = styled.div.attrs({
//     className: classnames('py-0', 'relative')
// })``;

const ContentClassBase = classnames('relative');
export const GridContent = () => styled.div.attrs({
    className: classnames(ContentClassBase, 
        'grid', 'gap-4', 'grid-cols-1', 'md:grid-cols-1', 'lg:grid-cols-2')
})``;

export const FlexContent = () => styled.div.attrs({
    className: classnames(ContentClassBase, 'flex', 'flex-col')
})``;
import React from 'react';
import { BoxContainer } from './styles';
import { IProps } from './types';

export const CardBoxContainer: React.FC<IProps> = ({
    children,
    roundedSize,
    ...props
}) => {
    roundedSize = roundedSize || 'sm';
    const Container = BoxContainer(roundedSize);
    return <Container>
        {children}
    </Container>
}
import React, { Component } from 'react'
import { AdminUsersFilter } from '../../../../hooks'
import { AdminUsersSearchBarForm } from './AdminUsersSearchBarForm'

interface IProps {
    onSearchChanged: (filter: AdminUsersFilter) => void;
}

export class AdminUsersSearchBar extends Component<IProps> {
    render() {
        return (
            <div className="w-full flex flex-col md:flex-row bg-gpas-700 dark:bg-gray-800 text-white rounded-sm px-6 py-2">
                <div className="flex-grow">
                    <h2 className="text-white">Búsqueda</h2>
                </div>
                <div className="flex flex-row items-center">
                    <AdminUsersSearchBarForm onSearchChanged={this.props.onSearchChanged} />
                </div>
            </div>
        )
    }
}

import React, { useEffect } from 'react';
import { IntroBox, IntroLeftBox } from './styles';
import { IProps } from './types';
import { AuthContext } from '../../contexts/AuthContext';
import { LinkButton, UserPicture } from '../../components/atoms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faKey, faSignIn } from '@fortawesome/free-solid-svg-icons';
import { ContentWrapper } from '../../components/containers';
import { Link, useNavigate } from 'react-router-dom';
import { withLayout } from '../../hocs/withLayout';

const Welcome: React.FC<IProps> = ({ ...props }) => {
    const planningCenterSigninURL = `${process.env.REACT_APP_API_BASE_URL}/planning-center/authorize`;
    const authContext = React.useContext(AuthContext);


    // useEffect(() => {
    //     if (authContext.isAuthenticated) {
    //         history('/');
    //         return;
    //     }
    //     return () => {
    //     }
    // }, [history]);


    return (
        <ContentWrapper>
            <div className="flex-wrap md:flex -mx-6 xl:pt-10">
                <IntroBox>
                    <h1 className="tracking-tight text-4xl text-gpas-700 dark:text-white md:text-5xl font-bold xl:pt-20 pb-2">
                        Grupos PAS
                    </h1>
                    <span className="text-lg sm:block text-gpas-600 dark:text-gpas-200 leading-5 lg:max-w-sm pb-4 font-semibold mb-2">
                        ¡Todos los estudios y recursos <br /> necesarios para tu grupo pequeño!
                    </span>

                    <div className="mt-6 flex">
                        {
                            !authContext.isAuthenticated ?
                                <div className="flex flex-col space-y-3">
                                    <div className="flew-wrap w-full block bg-gpas-100 dark:bg-gray-800 text-gpas-700 dark:text-white p-4 rounded-md">
                                        <p className="pb-2">Para iniciar sesión deberás tener una cuenta en <strong> Planning Center</strong>, una vez que te registres deberás ser aprobado por el equipo de Grupos PAS.</p>
                                    </div>
                                    <div className="block">
                                        <div className="text-center">
                                            <LinkButton buttonStyle="button" url={planningCenterSigninURL} icon={faSignIn}>
                                                Iniciar sesión
                                            </LinkButton>
                                        </div>
                                    </div>
                                </div>

                                :
                                <div className="w-full flex-wrap bg-gray-800 px-6 py-4 rounded-md hover:scale-5 transition-transform delay-75">
                                    <Link to="/" >
                                        <h3 className="text-gpas-100 font-extrabold pb-1 uppercase">¡Bienvenido, {authContext.user?.firstname}!</h3>
                                        <div className="flex cursor-pointer pb-2">
                                            {
                                                authContext.isAuthenticated && authContext.user &&
                                                <div className="relative flex space-x-4 items-center text-white">
                                                    <UserPicture size="md" username={authContext.user.firstname} imageURL={authContext.user.avatarURL} />
                                                    <div className="relative flex flex-row space-x-1 items-center">
                                                        <span> ¡Estás listo, podés entrar!</span>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </Link>
                                </div>
                        }
                    </div>
                </IntroBox>

                <IntroLeftBox>
                    <img className="object-cover rounded-lg" src="/assets/img/groups/groups-1.jpg" alt="" />
                </IntroLeftBox>
            </div>

        </ContentWrapper>
    )
}

export const WelcomePage = withLayout(Welcome);
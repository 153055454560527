import { useState } from 'react';
import { IResource } from '../../types';
import { ResourceController } from '../../lib/controllers';
import { FetchState, ResourceTypeType } from '../../types/common';

const ctrl = new ResourceController();
let cache: IResource[];

export const useResourceGroupResources = (type: ResourceTypeType) => {
    const [state, setState] = useState<FetchState>('none');
    const [data, setData] = useState<IResource[]>();

    const fetchResources = async (groupId: string): Promise<IResource[]> => {
        setState('fetching');
        await fetchData(groupId);
        return data||[];
    }

    const fetchData = async (groupId: string) => {
        if (state === 'fetching') {
            return;
        }

        if (cache) {
            setData(cache);
            setState('fetched');
            return;
        }

        const data = await ctrl.getResourceGroupResources(type, groupId);
        cache = data;
        setData(data);
        setState('fetched');
    }

    return {
        state,
        data,
        fetchResources
    };
}
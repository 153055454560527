import styled from 'styled-components';
import { classnames } from 'tailwindcss-classnames';

export const Content = styled.div.attrs({
    className: classnames('w-full', 'flex', 'justify-between', 'bg-white', 'relative' , 'sticky', 'z-20',
        'px-4', 'py-3', 'border-gray-200', 'shadow-sm', 'rounded-sm')
})``;

export const TitleContent = styled.div.attrs({
    className: classnames('flex', 'space-x-2', 'items-center')
})``;

export const SearchInputContent = styled.div.attrs({
    className: classnames('flex', 'space-x-2', 'items-center')
})``;

export const OptionsContent = styled.div.attrs({
    className: classnames('right-0', 'flex', 'space-x-1', 'space-x-2')
})``;

export const OptionsAnchor = styled.a.attrs({
    className: classnames('hover:text-purple-400', 'hover:bg-purple-200', 'rounded-full', 
    'transition-all', 'cursor-pointer', 'h-auto', 'px-2', 'py-1')
})`
transition-duration: 0.5s;
`;
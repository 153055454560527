import { FieldProps  } from 'formik';
import React from 'react';
import { SpanError } from './styles';

interface IOption {
    text: string;
    value: any;
    id: string;
}

interface IProps extends FieldProps {
    placeholder?: string;
    options: IOption[];
    children: any;
}

export class FormSelectField extends React.Component<IProps> {
    render() {
        const { field } = this.props;
        const { touched, errors } = this.props.form;
        return <div>
            <select className="ring-1 ring-gray-300 rounded-md shadow-sm my-2 px-4 py-2 block w-full bg-gray-100 transition-shadow focus:shadow-md" {...field} >
                {this.props.children}
            </select>
            {
                touched[field.name] &&
                errors[field.name] &&  <SpanError>{errors[field.name] as string}</SpanError>            
            }
        </div>
    }
}
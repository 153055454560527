import { useState } from 'react'
import { SupportController } from '../../lib/controllers';
import { ISupportTicket, ISupportTicketComment } from '../../types';
import { FetchState } from '../../types/common'

const supportCtrl = new SupportController();

export const useSupportTickets = (options?: {isUserAdmin: boolean}) => {
    const [state, setState] = useState<FetchState>('none');
    const [commentsState, setCommentsState] = useState<FetchState>('none');
    const [data, setData] = useState<ISupportTicket[]>([]);
    const [isAdmin, setIsAdmin] = useState(options?.isUserAdmin||false);

    const fetchSupportTickets = async () => {
        if (state === 'fetching') {
            return;
        }
        setState('fetching');
        const result = await (isAdmin ? supportCtrl.getAdminTickets() :  supportCtrl.getTickets());
        setData(result);
        setState('fetched');
    }
    
    const fetchComments = async (supportTicketId: string): Promise<ISupportTicketComment[]> => {
        setCommentsState('fetching');
        const result = await supportCtrl.getTicketComments(supportTicketId);
        setCommentsState('fetched');
        return result;
    }

    const cleanData = () => {
        setData([]);
        setState('none');
    }

   return {
        data,
        state,
        cleanData,
        fetchComments,
        fetchSupportTickets
    }
}
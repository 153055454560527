import { useEffect, useState } from 'react'
import { SupportTicketCategoryController } from '../../lib/controllers';
import { ISupportTicketCategory } from '../../types';
import { FetchState } from '../../types/common'

const ctrl = new SupportTicketCategoryController();
let cache: any = {};

export const useSupportTicketCategory = () => {
    const [state, setState] = useState<FetchState>('none');
    const [data, setData] = useState<ISupportTicketCategory[]>([]);

    const fetchData = async () => {
        if (state === 'fetching') {
            return;
        }

        if (cache && cache.data) {
            setData(cache.data);
            setState('fetched');
            return cache.data;
        }

        setState('fetching');
        const result = await ctrl.fetchAll();
        setData(result);
        setState('fetched');
        cache = {
            data: result
        };
    }

    useEffect(() => {
        if (state ===  'fetching') {
            return;
        }
        fetchData();
        return () => {
            setData([]);
        }
    }, [])

    const cleanData = () => {
        setData([]);
        setState('none');
    }

   return {
        data,
        state,
        cleanData
    }
}
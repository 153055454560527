import styled from 'styled-components';
import { classnames } from 'tailwindcss-classnames';

export const Content = styled.div.attrs({
    className: classnames('px-0')
})``;

export const OptionsContent = styled.div.attrs({
    className: classnames('flex', 'py-3', 'space-x-4',  'content-end')
})`
border-color: whitesmoke;
`;
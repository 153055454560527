import { faHome, faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ContentWrapper } from '../../components/containers';
import { BackgroundPageTitle, SidebarMenu } from '../../components/molecules';
import { Container } from '../../components/molecules/SearchBar/styles';
import { AuthContext } from '../../contexts';
import * as S from './styles';
import { IProps } from './types';
import { withLayout } from '../../hocs/withLayout';
import { withAuth } from '../../hocs';

export const Profile: React.FC<IProps> = ({ ...props }) => {
    const {signOut, isAuthenticated} = useContext(AuthContext);
    const [mustLogOut, setMustLogOut] = useState(false);
    const navigate = useNavigate();

    const signOutAction = async (e: any) => {
        e.preventDefault();
        await signOut();        
        setMustLogOut(true);
    }

    useEffect(() => {
        if (mustLogOut) {
            navigate('/');
        }
        return () => {            
        }
    }, [mustLogOut])
    
    return (
        <>
            <BackgroundPageTitle imageURL="/assets/img/temp/temp-2.jpg" title="Mi perfil" subtitle="Sobre tu cuenta en Grupos PAS" heigthSize="sm" />
            <ContentWrapper>
                <S.ProfileContainer>
                    <S.Sidebar>
                        <SidebarMenu subtitle="Algunos accesos rápidos" title="Opciones" items={[
                            { title: 'Inicio', url: '/', icon: faHome },
                            { title: 'Mi perfil', url: '/', icon: faUser },
                            // { title: 'Cerrar sesión', url: '/cerrar-sesion', icon: faSignOutAlt },
                        ]} />
                    <button className="flex text-white mt-2 rounded-sm bg-red-500 px-4 py-2 space-x-1 items-center" onClick={signOutAction}>
                        <FontAwesomeIcon icon={faSignOutAlt} />
                        <span>Cerrar sesión </span>
                        </button>
                    </S.Sidebar>

                    <S.Content>
                        <Container>
                        </Container>
                    </S.Content>
                </S.ProfileContainer>
            </ContentWrapper>
        </>
    )
}

export const ProfilePage = withLayout(withAuth(Profile))
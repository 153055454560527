import React from 'react';
import ReactMarkdown from 'react-markdown';

interface IProps {
    content: string;
}

export class Markdown extends React.Component<IProps> {
    render() {
        return <ReactMarkdown children={this.props.content} />
    }
}
import { Field, Formik } from 'formik';
import React, { Component } from 'react'
import { UserStatusEnum } from '../../../../enums';
import { AdminUsersFilter } from '../../../../hooks/Users';
import { FormInputField, FormSelectField } from '../../../atoms';

interface IForm {
    fisrtname?: string;
    lastname?: string;
    status?: UserStatusEnum | '*';
    pagFetch?: number;
    pagCount?: number;
}

interface IProps {
    onSearchChanged: (filter: AdminUsersFilter) => void;
}

export class AdminUsersSearchBarForm extends Component<IProps> {
    render() {
        return (
            <Formik initialValues={{
                firstname: '',
                lastname: '',
                status: UserStatusEnum.VERIFICATION
            }}
                initialStatus="none"
                validate={(values) => {
                    const errors = {} as IForm;
                    return errors;
                }}
                onSubmit={(values, actions) => {
                    actions.setStatus('sending');
                    this.props.onSearchChanged({
                        firstname: values.firstname,
                        lastname: values.lastname,
                        status: values.status || '*',
                    });
                    actions.setStatus('sent');
                    actions.setSubmitting(false);
                }}>
                {
                    ({ status, handleSubmit, isSubmitting, handleChange }) => (
                        <form className="flex text-gray-800 flex-col md:flex-row md:space-x-2 w-full" noValidate onSubmit={handleSubmit}>
                            <div>
                                <Field component={FormInputField} name="firstname" type="text" placeholder="Nombre" onChange={handleChange} />
                            </div>

                            <div>
                                <Field component={FormInputField} name="lastname" type="text" placeholder="Apellidos" onChange={handleChange}/>
                            </div>

                            <div>
                                <Field component={FormSelectField} className="text-base" name="status" placeholder="Estado" onChange={handleChange}>
                                    <option value={'*'}>- Todos -</option>
                                    <option value={UserStatusEnum.ENABLED}>Aprobado</option>
                                    <option value={UserStatusEnum.VERIFICATION}>Pendiente aprobación</option>
                                </Field>
                            </div>
                            <div>
                                <button disabled={isSubmitting} className="bg-gpas-100 hover:bg-gpas-200 hover:text-gpas-700 text-gpas-700 rounded-sm px-4 py-2 items-center space-x-2 my-2" type="submit"> 
                                Buscar</button>
                            </div>
                        </form>
                    )
                }
            </Formik>
        )
    }
}

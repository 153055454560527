import React from 'react';

export const PillText: React.FC<any> = (props: {
    text: string, type: 'success' | 'warning' | 'danger'
}) => {
    const bgColor = props.type === 'success' ? 'green' :
        props.type === 'danger' ? 'red' :
            props.type === 'warning' ? 'yellow' : 'blue';
    const pillClass = `bg-${bgColor}-300 text-${bgColor}-900 px-2 py-0 text-md font-bold rounded-md text-center hover:scale-105 transition-transform`;
    return (
        <div className={pillClass}>
            {props.text}
        </div>
    )
}
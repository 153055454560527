import React from 'react';
import { Field, Formik } from 'formik';
import { FormSelectField, FormTextareaField } from '../../../components/atoms';
import { MessageLabel } from '../../../components/molecules';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { ISupportTicketCategory } from '../../../types';

interface IForm {
    message: string;
    supportTicketCategoryId: string;
}

interface IProps {
    onSendForm: (values: IForm) => Promise<any>;
    supportTicketCategories: ISupportTicketCategory[];
}

interface IState {
    selectedCategory?: ISupportTicketCategory;
}

export class AskForm extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props);
        this.onCategoryChanged = this.onCategoryChanged.bind(this);
    }

    onCategoryChanged(e: any, cat: ISupportTicketCategory) {
        e.preventDefault();
        this.setState({
            selectedCategory: cat
        });

    }

    render() {
        return <div className="mt-5 md:mt-0 md:col-span-2">
            <Formik initialValues={{
                message: '',
                supportTicketCategoryId: ''
            }}
                initialStatus="none"
                validate={(values) => {
                    const errors = {} as IForm;

                    if (!values.message) {
                        errors.message = 'Es necesario que nos escribás tus comentarios.'
                    }

                    if (!values.supportTicketCategoryId) {
                        errors.supportTicketCategoryId = 'Debe seleccionar una categoría.'
                    }
                    return errors;
                }}
                onSubmit={(values, actions) => {
                    actions.setStatus('sent');
                    // values.supportTicketCategoryId = this.props.supportTicketCategories[0].id;
                    this.props.onSendForm(values);
                }}>
                {
                    ({ status, handleSubmit, isSubmitting }) => (
                        <form noValidate onSubmit={handleSubmit}>
                            <div className="relative flex-wrap space-y-6">
                                <div>
                                    <label htmlFor="category" className="block text-sm font-medium text-gray-700">
                                        Tipo de consulta
                                    </label>
                                    <Field name="supportTicketCategoryId" component={FormSelectField}>
                                        {
                                            this.props.supportTicketCategories.map(cat => (
                                                <option key={cat.id} className="w-full inline-block px-2 py-1" id={cat.id} value={cat.id} onChange={(e) => this.onCategoryChanged(e, cat)}>{cat.name}</option>
                                            ))
                                        }
                                    </Field>
                                </div>

                                <div>
                                    <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                                        Escribínos tus comentarios
                                        </label>
                                    <Field name="message" rows={6} placeholder="Ingresá acá tu consulta o comentario" component={FormTextareaField} />
                                </div>
                                {
                                    status === 'sent' &&
                                    <MessageLabel icon={faCheckCircle} type="success" text="Mensaje recibido. Nos pondremos en contacto pronto." />
                                }

                                <div>
                                    <button hidden={status === 'sent'} disabled={isSubmitting} className="bg-gpas-700 hover:bg-gpas-900 hover:text-white text-gpas-100 rounded-sm px-4 py-2" type="submit"> Enviar consulta</button>
                                </div>
                            </div>
                        </form>
                    )
                }
            </Formik>
        </div>
    }
}
import React from 'react';
import { CardBoxContainer } from '../../containers';
import * as S from './styles'

interface IProps {
    title: string;
    subtitle?: string;
    children?: React.ReactNode;
}

export class SideSearch extends React.Component<IProps> {
    render() {
        return (
            <CardBoxContainer>
                <S.Header>
                    <div className="flex relative items-center space-x-1">
                        <S.Title>{this.props.title}</S.Title>
                    </div>
                    {
                        this.props.subtitle &&
                        <p className="text-sm leading-4 text-gpas-700 dark:text-gpas-100 pb-3">{this.props.subtitle}</p>
                    }
                </S.Header>
                <S.Container>
                    <S.Content>
                        {this.props.children}
                    </S.Content>
                </S.Container>
            </CardBoxContainer>
        )
    }
}
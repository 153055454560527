import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { ImageCardBox } from '../ImageCardBox';

interface IResourceAttribute {
    icon: IconDefinition;
    title?: string;
    value: string;
}

interface IProps {
    to: string;
    title: string;
    imageURL: string;
    subtitle: string;
    attributes?: IResourceAttribute[];
    size: 'sm' | 'md' | 'lg';
    hideSubtitle?: boolean;
}

export class ResourceCardItem extends React.Component<IProps> {
    render() {
        return (
            <ImageCardBox size={this.props.size}
                imageURL={this.props.imageURL}
                shape="squ"
                to={this.props.to}
                hideSubtitle={this.props.hideSubtitle}
                title={this.props.title}
                subtitle={this.props.subtitle}
                cardOptions={this.props.attributes}>
            </ImageCardBox>
        )
    }
}
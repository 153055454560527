import React from 'react';
import { faBook } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon } from '../../atoms';
import * as S from './styles';
import * as T from './types';

export class GridSearchBar extends React.Component<T.IProps> {
    constructor(props: T.IProps) {
        super(props);
        this.onOptionPressed = this.onOptionPressed.bind(this);
    }

    onOptionPressed(id: string) {
        this.props.onAction(id);
    }

    render() {
        return <>
            <S.Content>
                <S.TitleContent>
                    <Icon icon={faBook} />
                    <span className="text-lg text-gpas-700">{this.props.title}</span>
                </S.TitleContent>
                <S.OptionsContent>
                    {
                        this.props.options && this.props.options.length > 0 &&
                        this.props.options.map(option => (
                            <S.OptionsAnchor key={option.id} title={option.title} onClick={() => this.onOptionPressed(option.id)}>
                                <FontAwesomeIcon icon={option.icon} />
                            </S.OptionsAnchor>
                        ))
                    }
                </S.OptionsContent>
            </S.Content>
        </>
    }
}
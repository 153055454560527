import React from 'react';
import { CardBoxContainer } from '../../containers';
import { IProps } from './types';
import * as S from './styles';
import { Title } from '../../atoms';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const SidebarMenu: React.FC<IProps> = ({
    items,
    subtitle,
    title,
    ...props }) => {
    return (
        <CardBoxContainer>
            <S.Container>
                <S.HeaderContainer>
                    <Title color='text-white' colorDark='text-white' heading="h5">{title}</Title>
                </S.HeaderContainer>
                <S.ItemsContent>
                    {
                        items.map((item, ix) => (
                            !item.isExternal ?
                                <Link className="block py-2 pl-6 w-full cursor-pointer dark:text-white hover:bg-gpas-500 hover:text-white transition-all transform" to={item.url} key={ix}>
                                    <FontAwesomeIcon icon={item.icon} className="text-gpas-700 hover:text-white mr-2" />
                                    <span className="hover:font-semibold hover:scale-105 text-sm">
                                        {item.title}
                                    </span>
                                </Link> :
                                <a className="block py-2 pl-6 w-full cursor-pointer hover:scale-105" href={item.url} key={ix}>
                                    <FontAwesomeIcon icon={item.icon} className="text-gpas-700 mr-2" />
                                    <span className="hover:font-semibold hover:scale-105 transform text-sm">
                                        {item.title}
                                    </span>
                                </a>
                        ))
                    }
                </S.ItemsContent>
            </S.Container>
        </CardBoxContainer>
    );
}
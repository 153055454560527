import React from 'react';
import { CarouselProvider, Slider } from 'pure-react-carousel';

interface IProps {
    children?: React.ReactNode;
    slidesCount: number;
    visibles: number;
    naturalSlideWidth: number;
    naturalSlideHeight: number;
}

export class Carousel extends React.Component<IProps> {
    render() {
        return (
            <CarouselProvider
                isPlaying={true}
                infinite={true}
                isIntrinsicHeight={true}
                totalSlides={this.props.slidesCount}
                visibleSlides={this.props.visibles}
                naturalSlideHeight={this.props.naturalSlideHeight}
                naturalSlideWidth={this.props.naturalSlideWidth}
                orientation="horizontal">
                <Slider className="space-x-2" >
                    {this.props.children}
                </Slider>
            </CarouselProvider>
        )
    }
}
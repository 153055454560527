import React from 'react';
import * as S from './styles';
import { Title } from '../../atoms';
import { IFile } from '../../../types/common';

interface IProps {
    title: string;
    subtitle: string;
    files?: IFile[];
    children?: React.ReactNode;
    goBackAction: () => void;
}

interface IState {
    hidden: boolean;
}

export class ResourceSidebar extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props);
        this.onGoBackAction = this.onGoBackAction.bind(this);
        this.onHideAction = this.onHideAction.bind(this);
        this.state = {
            hidden: false
        }
    }

    onGoBackAction(e: any) {
        e.preventDefault();
        this.props.goBackAction();
    }

    onHideAction(e: any) {
        e.preventDefault();
        this.setState({
            hidden: !this.state.hidden
        });
    }

    render() {
        return <>
                <S.Container>
                    <div className={`${this.state.hidden ? 'hidden' : 'inline'} transform transition-all`}>
                        <S.Header>
                            <Title color="text-gpas-700" heading="h3">{this.props.title}</Title>
                        </S.Header>
                    </div>
                    <S.Content>
                        <div className={`${this.state.hidden ? 'hidden' : 'inline'} transform transition-all`}>
                            <S.ResourceContainer>
                                {this.props.children}
                            </S.ResourceContainer>
                        </div>
                    </S.Content>

                    <div className={`relative flex ${this.state.hidden ? 'flex-col' : 'flex-row'} py-2 space-x-0 md:space-x-2`}>
                        <button className="flex cursor-pointer bg-gpas-600 dark:bg-gpas-700 rounded-sm px-3 py-1 text-white text-sm items-center space-x-2" onClick={this.onGoBackAction}>
                            Volver a estudios</button>

                        {/* <button className="flex cursor-pointer bg-gpas-600 rounded-sm px-3 py-1 text-white text-sm items-center space-x-2" onClick={this.onHideAction}>
                            Ocultar</button> */}
                    </div>
                </S.Container>
        </>
    }
}

import React from 'react';
import { Link } from 'react-router-dom';
import { UserPicture } from '../../atoms';
import { Container } from './styles';
import { IProps } from './types';

export const UserProfile: React.FC<IProps> = ({
    username,
    firstname,
    imageURL,
    ...props }) => {
    return (
        <Container>
            <Link to="/perfil">
                <div className="flex items-center text-white text-sm">
                    <span className="hidden md:inline">
                        {firstname}
                    </span>
                    <UserPicture size="md" username={firstname} imageURL={imageURL} />
                </div>
            </Link>
            {/* <OptionsContainer>
                Hola
            </OptionsContainer> */}
        </Container>
    )
}
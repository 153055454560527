import { AddSupportTicketDto } from '../../dto';
import { IAskForm } from '../../pages/FAQ/AskPage/types';
import { ISupportTicket, ISupportTicketComment } from '../../types';
import ControllerRequest from './_controller.base';

export class SupportController extends ControllerRequest {
    constructor() {
        super('support');
    }

    addTicket(ticket: IAskForm): Promise<ISupportTicket> {
        return this.execPOST(ticket, '/ticket');
    }

    addTicketComment(ticket: ISupportTicket, ticketComment: AddSupportTicketDto): Promise<any> {
        return this.execPOST(ticketComment, `/ticket/${ticket.id}/comment`);
    }

    getTickets(): Promise<ISupportTicket[]> {
        return this.execGET('/ticket');
    }

    getAdminTickets(): Promise<ISupportTicket[]> {
        return this.execGET('/admin/ticket');
    }

    getTicketComments(stId: string): Promise<ISupportTicketComment[]> {
        return this.execGET(`/ticket/${stId}/comment`);
    }
}
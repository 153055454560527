import React from 'react';
import { Route, Routes } from "react-router-dom";
import * as Pages from './pages';
import { AuthContext } from './contexts/AuthContext/AuthContext';

class AppRoutes extends React.Component {
    static contextType = AuthContext;
    context!: React.ContextType<typeof AuthContext>;

    componentDidMount() {
        this.context.initAppSession().then();
    }

    render() {
        if (!this.context.isAuthLoaded) {
            return <span className="animate-spin text-gray-300">...</span>;
        }


        return (
                <Routes>
                    <Route path='/bienvenidos' element={<Pages.WelcomePage />} />
                    <Route path='/signin-callback' element={<Pages.SigninCallbackPage />} />
                    <Route path='/esperando-aprobacion' element={<Pages.WelcomeWaitingForApproval />} />

                    {/* Priveate Routes */}
                    <Route path='/' element={<Pages.HomePage />} />
                    <Route path='/estudios' element={<Pages.StudiesPage />} />
                    <Route path='/estudios/:slug' element={<Pages.SingleStudyPage />} />
                    <Route path='/recursos' element={<Pages.ResourcesPage />} />
                    <Route path='/guias' element={<Pages.GuidesPage />} />
                    <Route path='/noticias/:slug' element={<Pages.NewsSinglePage />} />
                    <Route path='/perfil' element={<Pages.ProfilePage />} />
                    <Route path='/cerrar-sesion' element={<Pages.SignOutPage />} />

                    {/* Admin */}
                    <Route path='/gestion-usuarios' element={<Pages.UserDetailsForAdminPage />} />
                </Routes>
        )
    }
}

export default AppRoutes;